import React from "react";
import { useNavigate } from 'react-router-dom';
import { FaWhatsapp, FaExclamationTriangle, FaArrowAltCircleLeft } from 'react-icons/fa';

export default function ErrorCard({ id, message, onClose }) {
  const overlayStyle = {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.9)', // Fundo escurecido
    zIndex: '999', // Garante que fique atrás do cardStyle
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const cardStyle = {
    backgroundColor: '#f9f9f9',
    border: '1px solid #ccc',
    borderRadius: '15px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    zIndex: '1000',
    maxWidth: '90%', // Para garantir que seja responsivo
    margin: '0 auto', // Centraliza horizontalmente
    padding: '20px',
  };

  const buttonStyle = (backgroundColor) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 20px',
    backgroundColor,
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 'bold',
    margin: '10px auto',
    transition: 'opacity 0.3s',
    width: '80%',
    maxWidth: '200px',
  });

  const iconStyle = {
    marginBottom: '15px',
    display: 'flex',
    justifyContent: 'center',
  };

  const navigate = useNavigate();

  const goToPlanos = () => {
    navigate('/home');
  };

  const openWhatsApp = () => {
    const phoneNumber = '+5524999375062';
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <div style={overlayStyle}>
      <div style={cardStyle}>
        <div style={iconStyle}>
          <FaExclamationTriangle color="#FFA500" size="28" />
        </div>
        <p>{message}</p>
        <button onClick={goToPlanos} style={buttonStyle('#4CAF50')}>
          <FaArrowAltCircleLeft style={{ marginRight: '10px' }} />
          Pagina Inicial
        </button>
        <button onClick={openWhatsApp} style={buttonStyle('#25D366')}>
          <FaWhatsapp style={{ marginRight: '10px' }} />
          Entrar em Contato
        </button>
      </div>
    </div>
  );
}
