import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserAuth } from '../../context/AuthContext';
import NutriImagem from '../../img/SignMasc.png'; // Importe sua imagem de logo aqui

const Signin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { signIn } = UserAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await signIn(email, password);
      navigate('/home');
    } catch (e) {
      if (e.message === "Firebase: Error (auth/user-not-found).") {
        setError("Email Incorreto");
      } else if (e.message === "Firebase: Error (auth/wrong-password).") {
        setError("Senha Incorreta");
      }
    }
  };

  return (
    <div className="max-w-md mx-auto mt-8 p-6 bg-white shadow-md rounded-lg">
      <div className="text-center mb-4">
        <img src={NutriImagem} alt="Logo" className="w-44 mx-auto mb-4" />
        <h2 className="text-1xl font-bold text-gray-800">Bem-vindo </h2>
        </div>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="email" className="block text-gray-700 font-medium">Email</label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="form-input mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
            placeholder="Seu email"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="password" className="block text-gray-700 font-medium">Senha</label>
          <input
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="form-input mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
            placeholder="Sua senha"
            autoComplete="current-password"
            required
          />
        </div>
        {error && <p className="text-center text-red-600 mb-4">{error}</p>}
        <button
  type="submit"
  className="w-full bg-[#35C759] text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline hover:bg-green-600"
>
  ENTRAR
</button>

<div className="mt-4 text-center">
  <p>
    Esqueceu sua senha?{' '}
    <Link to="/redefinirsenha" className="text-[#35C759] font-semibold">
      REDEFINIR
    </Link>
  </p>
</div>
<div className="mt-4 text-center">
  <p>
    Ainda não possui uma conta?{' '}
    <Link to="/signup" className="text-[#35C759] font-semibold">
      CRIAR CONTA
    </Link>
  </p>
</div>
      </form>
    </div>
  );
};

export default Signin;
