import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserAuth } from '../../../context/AuthContext';
import NutriImagem from '../../../img/SignMasc.png'; // Importe sua imagem de logo aqui

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [termoscheck, setTermosCheck] = useState(false);
  const [error, setError] = useState('');
  const { createUser } = UserAuth();
  const navigate = useNavigate();

  const handleCheck = () => {
    setTermosCheck(!termoscheck);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      if (password !== confirmPassword) {
        setError('As senhas não são iguais. Por favor, verifique novamente.');
        return;
      }

      if (!termoscheck) {
        setError('Você deve concordar com os termos e condições para criar uma conta.');
        return;
      }

      await createUser(email, password);
      navigate('/home');
    } catch (e) {
      if (e.message === 'Firebase: Error (auth/email-already-in-use).') {
        setError('Email já existente. Faça o login ou crie com outro email.');
      } else if (e.message === 'Firebase: Password should be at least 6 characters (auth/weak-password).') {
        setError('Sua senha precisa ter mais de 6 caracteres.');
      }
    }
  };

  return (
    <div className="max-w-md mx-auto mt-8 p-6 bg-white shadow-md rounded-lg">
      <div className="text-center mb-4">
        <img src={NutriImagem} alt="Logo" className="w-44 mx-auto mb-4" />
        <h2 className="text-1xl font-bold text-gray-800">Crie sua Conta</h2>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="email" className="block text-gray-700 font-medium">Email</label>
          <input
            id="email"
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            className="form-input mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
            placeholder="Seu email"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="password" className="block text-gray-700 font-medium">Senha</label>
          <div className="relative">
            <input
              id="password"
              type={showPassword ? 'text' : 'password'}
              onChange={(e) => setPassword(e.target.value)}
              className="form-input mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
              placeholder="Sua senha"
              autoComplete="new-password"
              required
            />
            <span
              className="absolute top-1/2 right-4 transform -translate-y-1/2 cursor-pointer"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? '👁️' : '👁️‍🗨️'}
            </span>
          </div>
        </div>
        <div className="mb-4">
          <label htmlFor="confirmPassword" className="block text-gray-700 font-medium">Confirmar Senha</label>
          <input
            id="confirmPassword"
            type={showPassword ? 'text' : 'password'}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="form-input mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
            placeholder="Confirme sua senha"
            autoComplete="new-password"
            required
          />
        </div>
        <div className="flex items-center mb-4">
          <input
            id="termoscheck"
            type="checkbox"
            checked={termoscheck}
            onChange={handleCheck}
            className="form-checkbox h-4 w-4 text-green-600 rounded"
          />
          <label htmlFor="termoscheck" className="ml-2 text-sm text-gray-700">
            Eu estou de acordo com os{' '}
            <Link to="/termos" target="_blank" className="text-[#35C759] font-semibold">
              Termos e Condições de uso
            </Link>{' '}
            da Nutri Inteligente
          </label>
        </div>
        {error && <p className="text-center text-red-600 mb-4">{error}</p>}
        <button
          type="submit"
          className="w-full bg-[#35C759] text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline hover:bg-green-600"
        >
          CRIAR CONTA
        </button>
        <div className="mt-4 text-center">
          <p>
            Já possui uma conta?{' '}
            <Link to="/" className="text-[#35C759] font-semibold">
              ENTRAR COM CONTA
            </Link>
          </p>
        </div>
      </form>
    </div>
  );
};

export default Signup;
