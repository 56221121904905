import React, { useState, useEffect, useRef } from "react";
import "./../ChatContainer/ChatContainer.css";
import { UserAuth } from "../../context/AuthContext";
import { useNavigate } from 'react-router-dom';
import { IMaskInput } from "react-imask";
import { TailSpin } from 'react-loader-spinner';
// import ErrorCard from "../Error/ErrorCard2";

function ChatContainer({
  sendRequest, answer
}) {

  const resultTextareaRef = useRef(null);
  const navigate = useNavigate();

  const [name, setName] = useState("")
  const [weight, setWeight] = useState("");
  const [height, setHeight] = useState("");
  const [age, setAge] = useState("");
  const [goal, setGoal] = useState("");
  const [horarios, setHorarios] = useState("");
  const [chocolate, setChocolate] = useState("");
  const [comorbidades, setComorbidades] = useState("")
  const [selectedFoods1, setSelectedFoods1] = useState([]);
  const [selectedFoods2, setSelectedFoods2] = useState([]);
  const [selectedFoods3, setSelectedFoods3] = useState([]);
  

  const selecionarGenero = (genero) => {
    setGeneroSelecionado(genero);
  };




  const availableFoodsCarb = [
    { img: "🥚", food: "Ovo", foodd: "Ovo" },
    { img: "🧀", food: "Queijo", foodd: "Queijo" },
    { img: "🥙", food: "Tapioca", foodd: "Tapioca com recheio fitness" },
    { img: "🥞", food: "Crepioca", foodd: "Crepioca com recheio fitness" },
    { img: "🍎", food: "Fruta", foodd: "Fruta" },
    { img: "🥛", food: "Iogurte", foodd: "Iogurte" },
    { img: "🍪", food: "Biscoito", foodd: "Biscoito de polvilho ou agua e sal ou maisena" },
    { img: "☕", food: "Café", foodd: "Café" },
    { img: "🧀", food: "Pão-Queijo", foodd: "Pão de Queijo" },
    { img: "🥖", food: "Pão + Ovo", foodd: "Pão com Ovo mexido ou omelete" },
    { img: "🌰", food: "Pasta", foodd: "Pasta de amendoin" },
    { img: "🧈", food: "Requeijão", foodd: "Requeijão" },
    { img: "🍚", food: "Cuscuz ", foodd: "Cuscuz " },
    { img: "🍞", food: "Pão + Queijo", foodd: "Pão com Queijo" },
    { img: "🥓", food: "Pão + Presunto", foodd: "Pão com Presunto" },
  ];
  
  const availableFoodsProtein = [
    { img: "🍗 ", food: "Frango", foodd: "Frango" },
    { img: "🥩 ", food: "Carne", foodd: "Carne" },
    { img: "🥔", food: "Mandioca", foodd: "Mandioca" },
    { img: "🐖", food: "Carne-Porco", foodd: "Carne-Porco" },
    { img: "🍠", food: "Batata-Doce", foodd: "Batata Doce" },
    { img: "🐟", food: "Peixes", foodd: "Peixes" },
    { img: "🍚", food: "Arroz", foodd: "Arroz" },
    { img: "🍲", food: "Feijão", foodd: "Feijão" },
    { img: "🥗", food: "Salada", foodd: "Salada de alface" },
    { img: "🍝", food: "Macarrão", foodd: "Macarrão" },
    { img: "🥚", food: "Ovo", foodd: "Ovo" },
    { img: "🍠", food: "Inhame", foodd: "Inhame" },
    { img: "🥦", food: "Legumes", foodd: "Brocolis, cenoura, abobora, ou tomate" },
    { img: "🍚", food: "Cuscuz", foodd: "Cuscuz" },
    { img: "🥔", food: "Batata", foodd: "Batata Inglesa" },
  ];

const availableFoodsFrutas = [
  { img: "🥛", food: "Whey", foodd: "Whey com agua ou leite" },
  { img: "🍍", food: "Fruta", foodd: "Fruta" },
  { img: "🍪", food: "Biscoito", foodd: "Biscoito de polvilho ou agua e sal ou maisena" },
  { img: "🥣", food: "Cereal", foodd: "Cereal" },
  { img: "🥤", food: "Suco", foodd: "Suco" },
  { img: "🥛", food: "Leite", foodd: "Leite" },
  { img: "🌯", food: "Rap10", foodd: "Rap10 com recheio fitness" },
  { img: "🥚", food: "Ovo", foodd: "Ovo" },
  { img: "🌾", food: "Aveia", foodd: "Aveia" },
  { img: "🥪", food: "Sanduíche Frango ", foodd: "Sanduíche Frango " },
  { img: "🍬", food: "Barra de Cereais", foodd: "Barra de Cereais" },
  { img: "🥪", food: "Sanduíche de Peru", foodd: "Sanduíche de Peru" },
];


  const [error, setError] = useState("");
  const [recomend, setRecomend] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const {user, retornaTicket, adicionarDieta, adicionarTicketUsado, salvarDados, adicionarEdicaoDieta, retirarTicket } = UserAuth();
  const [userTickets, setUserTickets] = useState(null);
  // const [userDieta, setUserDieta] = useState(null);
  const [exercicio, setExercicio] = useState("")
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [genero, setGeneroSelecionado] = useState('');
  const [calorias, setCalorias] = useState('')
  // const [showError3,setShowError3] =  useState(false)

  const goToPlanos = () => {
    navigate('/planos');
  };

  const handleSave = () => {
    adicionarDieta(resultTextareaRef.current.value);
    adicionarEdicaoDieta()
    
    navigate('/dieta')
    return;
  };


  // const closeError3 = () => {
  //   setShowError3(false);
  // }

  function handleFoodSelectionCarb(
    selectedFoods,
    setSelectedFoods,
    food,
    foodd,
  ) {
    if (selectedFoods.some((item) => item.foodd === foodd)) {
      setSelectedFoods(selectedFoods.filter((item) => item.foodd !== foodd));
    } else if (selectedFoods.length < 20) {
      setSelectedFoods([...selectedFoods, { food, foodd }]);
    }
  }

  function handleFoodSelectionProtein(
    selectedFoods,
    setSelectedFoods,
    food,
    foodd,
  ) {
    if (selectedFoods.some((item) => item.foodd === foodd)) {
      setSelectedFoods(selectedFoods.filter((item) => item.foodd !== foodd));
    } else if (selectedFoods.length < 20) {
      setSelectedFoods([...selectedFoods, { food, foodd }]);
    }
  }

  function handleFoodSelectionFrutas(
    selectedFoods,
    setSelectedFoods,
    food,
    foodd
  ) {
    if (selectedFoods.some((item) => item.foodd === foodd)) {
      setSelectedFoods(selectedFoods.filter((item) => item.foodd !== foodd));
    } else if (selectedFoods.length < 20) {
      setSelectedFoods([...selectedFoods, { food, foodd }]);
    }
  }

  // console.log(showError3)
  // useEffect(() => {
  //   retornaDieta()
  //     .then(async (dieta) => {
  //       if (dieta !== null) {
  //         await setUserDieta(dieta);
  //       } else {
  //         setUserDieta('');
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Erro ao obter os tickets:', error);
  //       setUserDieta('Erro ao obter os tickets');
  //     });
  // },[retornaDieta])

  useEffect(() => {
    retornaTicket()
      .then((tickets) => {
        if (tickets !== null) {
          setUserTickets(tickets);

        } else {
          setUserTickets("Nenhum ticket disponível");
        }
      })
      .catch((error) => {
        setUserTickets("Erro ao obter os tickets");
      });


  }, [retornaTicket, userTickets]);

  const handleSubmit = async () => {
    if (userTickets === 0) {
      navigate('/planos');

      return;
    } else if (!weight || !height || !goal) {
      setError("Por favor, preencha todos os campos obrigatórios.");
      return;
    }

    setIsLoading(true);
    document.body.classList.add("scroll-lock");
    const HomensTMB = parseInt((10 * weight + 6.25 * (height * 100) - 5 * age + 5) * exercicio)
    const MulheresTMB = parseInt((10 * weight + 6.25 * (height * 100) - 5 * age - 161) * exercicio)

    function calcularCalorias(goal, genero) {
      switch (goal) {
        case "Emagrecimento com facilidade":
          return genero === 'Masculino' ? `${HomensTMB - 700} a ${HomensTMB - 500}` : `${MulheresTMB - 700} a ${MulheresTMB - 500}`;
        case "Emagrecimento com facilidade + ganho de massa":
          return genero === 'Masculino' ?`${HomensTMB - 400} a ${HomensTMB - 200}`: `${MulheresTMB - 400} a ${MulheresTMB - 200}`;
        case "Jejum Intermitente":
          return genero === 'Masculino' ? `${HomensTMB - 600} a ${HomensTMB - 500}` : `${MulheresTMB - 600} a ${MulheresTMB - 500}`;
        case "Definição Muscular":
          return genero === 'Masculino' ? `${HomensTMB - 300} a ${HomensTMB - 200}` : `${MulheresTMB - 300} a ${MulheresTMB - 200}`;
        case "Bulking":
          return genero === 'Masculino' ? `${HomensTMB + 500} a ${HomensTMB + 700}` : `${MulheresTMB + 500} a ${MulheresTMB + 700}`;
        case "Definição e ganho de Massa Muscular":
          return genero === 'Masculino' ? `${HomensTMB + 200} a ${HomensTMB + 400}` : `${MulheresTMB + 200} a ${MulheresTMB + 400}`;
        default:
          return genero === 'Masculino' ? `${HomensTMB - 100} a ${HomensTMB + 100}  ` :  `${MulheresTMB - 100} a ${MulheresTMB + 100}  `

      }
    }

    const data = `Crie exclusivamente um plano de dieta para atingir o objetivo de ${goal} garanta que a soma das refeições para cada conjunto de opções (Opção 1 e Opção 2) separadamente seja de aproximadamente ${ calorias === 'Não' ? calcularCalorias(goal, genero) : calorias} calorias e distribua as quantidades e calorias entre as refeições, 
    seguindo estas diretrizes:
    - Não repetir as refeições de Almoço e Jantar.
    - Utilize esses horarios para as refeições ${horarios}
    - Apresente todas as quantidades em gramas e inclua as calorias ao final de cada opção.
    - Elabore o plano usando somente os alimentos listados, certifique-se que os alimentos das opções sejam combinadas de forma correta, variando entre as refeições:
      - 2 opções variadas Café da manhã: ${selectedFoods1.map((item) => item.foodd).join(", ")};
      - 2 opções variadas Lanche da manhã: ${selectedFoods3.map((item) => item.foodd).join(", ")};
      - 2 opções variadas Almoço: ${selectedFoods2.map((item) => item.foodd).join(", ")};
      - 2 opções variadas Lanche da tarde: ${selectedFoods3.map((item) => item.foodd).join(", ")};
      - 2 opções variadas Jantar: ${selectedFoods2.map((item) => item.foodd).join(", ")};
    - Considere as informações de altura ${height}, peso ${weight}, idade ${age} e leve em consideração a comorbidade ${comorbidades}
    - Finalize o plano sem adicionar mensagens após a última opção de jantar e inclua ${chocolate}.
    `;



    try {
      if(user.email === 'Email Cliente'){
        const interval = setInterval(() => {
          setLoadingProgress(prevProgress => {
            if (prevProgress >= 99) {
              clearInterval(interval);
              return prevProgress; // Mantém o progresso em 90% até a função terminar
            }
            return prevProgress + 1; // Incrementa 10% a cada intervalo
          });
        }, 700);
        await sendRequest(data);
        setLoadingProgress(100); // Completa a barra de progresso
        clearInterval(interval);
      }
      if (userTickets > 0) {
        if (genero === 'Feminino') {
          const interval = setInterval(() => {
            setLoadingProgress(prevProgress => {
              if (prevProgress >= 99) {
                clearInterval(interval);
                return prevProgress; // Mantém o progresso em 90% até a função terminar
              }
              return prevProgress + 1; // Incrementa 10% a cada intervalo
            });
          }, 700);
          await sendRequest(data)
          setLoadingProgress(100); // Completa a barra de progresso
          clearInterval(interval);

        } else if (genero === 'Masculino') {
          const interval = setInterval(() => {
            setLoadingProgress(prevProgress => {
              if (prevProgress >= 99) {
                clearInterval(interval);
                return prevProgress; // Mantém o progresso em 90% até a função terminar
              }
              return prevProgress + 1; // Incrementa 10% a cada intervalo
            });
          }, 700);
          await sendRequest(data)
          setLoadingProgress(100); // Completa a barra de progresso
          clearInterval(interval);

        } else {
          const interval = setInterval(() => {
            setLoadingProgress(prevProgress => {
              if (prevProgress >= 99) {
                clearInterval(interval);
                return prevProgress; // Mantém o progresso em 90% até a função terminar
              }
              return prevProgress + 1; // Incrementa 10% a cada intervalo
            });
          }, 700);
          await sendRequest(data);
          setLoadingProgress(100); // Completa a barra de progresso
          clearInterval(interval);
        }
      } else {
       console.log('Sem Ticket')
      }



      const imc = weight / (height * height)
      salvarDados(name,weight, height, parseInt(imc))
 

      setWeight("");
      setHeight("");
      setAge("");
      setGoal("");
      setHorarios("");
      setChocolate("");
      setSelectedFoods1([]);
      setSelectedFoods2([]);
      setSelectedFoods3([]);

      setError("");

      setRecomend(true);





    } catch (err) {
      console.error(err);
      setError('Estamos com bastante requisições! Coloque suas informações novamete para gerar sua dieta');
    } finally {
      setIsLoading(false);
      retirarTicket()
      adicionarTicketUsado()
      // Desativar o bloqueio de rolagem da página
      document.body.classList.remove('scroll-lock'); // Remova a classe
    }
  };
  return (
    <div className="chat-conteiner mb-0 mx-auto">
      <div className="chat-inputs mx-auto">
        <div className="chat-header">
          <h1>🥗</h1>
        <h2>Informações Gerais</h2>
        <p className="text-muted text-center text-xs mt-3">
          Preencha as informações do paciente abaixo.
        </p>
        </div>
        <div className="chat-input chat-input-peso">
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Nome"

          />
        </div>
        
        <div className="chat-input chat-input-peso">
          <input
            type="number"
            id="weight"
            maxLength={4}
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
            placeholder="Peso"

          />
        </div>
        <div className="chat-input">

          <IMaskInput
            mask="0.00"
            type="number"
            id="height"
            maxLength={4}
            value={height}
            onChange={(e) => setHeight(e.target.value)}
            placeholder="Altura"
          />
        </div>

        <div className="chat-input">

          <input
            type="number"
            id="age"
            value={age}
            onChange={(e) => setAge(e.target.value)}
            placeholder="Idade"

          />
        </div>
        <div className="select-wrapper">
          <select
            id="goal"
            value={goal}
            onChange={(e) => setGoal(e.target.value)}
          >
            <option value="">Objetivo - Paciente </option>
            <option value="Emagrecimento com facilidade">Emagrecer</option>
            <option value="Emagrecimento com facilidade + ganho de massa">Emagrecer + Massa Musucular</option>
            <option value="JejumIntermitente">Jejum Intermitente</option>
            <option value="Bulking com aproximadamente">Ganho Massa Muscular</option>
            <option value="Definição e ganho de Massa Muscular">
              Definição + Massa Muscular
            </option>
            <option value="Definição Muscular">Definição</option>
          </select>
        </div>
        <div className="select-wrapper">
          {<select
            id="horarios"
            value={calorias}
            onChange={(e) => setCalorias(e.target.value)}
          >
            <option value="">Calorias totais da Dieta 🔥</option>
            <option value="Não">Não sei dizer</option>
            <option value="1200 a 1500">1200 a 1500 calorias</option>
            <option value="1600 a 1900">1600 a 1900 calorias</option>
            <option value="2000 a 2300">2000 a 2300 calorias</option>
            <option value="2400 a 2700">2400 a 2700 calorias</option>
            <option value="2800 a 3100">2800 a 3100 calorias</option>
            <option value="3200 a 3500">3200 a 3500 calorias</option>
          </select>
          }
          <br></br>
          <br></br>
          <div className=" w-full flex justify-evenly">
            <button
              className={`button-inputM py-3 px-4 md:px-5 ${genero === 'Masculino' ? 'selected' : ''}`}
              onClick={() => selecionarGenero('Masculino')}
            >
              Masculino
            </button>
            <button
              className={`button-inputF py-3 px-4 md:px-5 ${genero === 'Feminino' ? 'selectedF' : ''}`}
              onClick={() => selecionarGenero('Feminino')}
            >
              Feminino
            </button></div>
        </div>
      </div>

      <h1 style={{ backgroundColor: '#35C759', color: '#FFFFFF' }} className="mx-auto p-3 rounded-full inline-block text-lg">
        Quais alimentos deseja incluir?
      </h1>
      <p className="p-0 m-0 mt-3 text-[#696767] text-center text-xs">Clique nos alimentos para selecionar</p>
      <div className="selection-food flex flex-col border font-bold rounded-lg mb-2 p-2">
        <label htmlFor="obs" className="text-color">Café da manhã ☕</label>
        <div className="food-container">
          {availableFoodsCarb.map((food, index) => (
            <label key={index} className={`food-item flex w-[100%] justify-center items-center text-sm md:text-lg ${selectedFoods1.some((item) => item.foodd === food.foodd) ? 'selectedfood ' : ''}`}>
              <input
                className="hidden"
                type="checkbox"
                value={food.food}
                checked={selectedFoods1.some((item) => item.foodd === food.foodd)}
                onChange={() =>
                  handleFoodSelectionCarb(
                    selectedFoods1,
                    setSelectedFoods1,
                    food.food,
                    food.foodd
                  )
                }
                disabled={
                  selectedFoods1.length === 20 &&
                  !selectedFoods1.some((item) => item.foodd === food.foodd)
                }

              />
              <span className="checkmark">
                {food.food}
                {food.img}
              </span>
            </label>
          ))}
        </div>
      </div>
      <div className="selection-food flex flex-col border rounded-lg font-bold mb-2 p-2">
        <label htmlFor="obs2" className="text-color">Almoço e Janta 🍽️</label>
        <div className="food-container">
          {availableFoodsProtein.map((food, index) => (
            <label key={index} className={`food-item flex w-[100%]  justify-center items-center text-sm md:text-lg ${selectedFoods2.some((item) => item.foodd === food.foodd) ? 'selectedfood' : ''}`}>
              <input
                className="hidden"
                type="checkbox"
                value={food.food}
                checked={selectedFoods2.some((item) => item.foodd === food.foodd)}
                onChange={() =>
                  handleFoodSelectionProtein(
                    selectedFoods2,
                    setSelectedFoods2,
                    food.food,
                    food.foodd,
                    
                  )
                }
                disabled={
                  selectedFoods2.length === 20 &&
                  !selectedFoods2.some((item) => item.foodd === food.foodd)
                }
              />
              <span className="checkmark">
                {food.food}
                {food.img}
              </span>
            </label>
          ))}
        </div>
      </div>

      <div className="selection-food flex flex-col border rounded-lg font-bold mb-2 p-2">
        <label htmlFor="obs3" className="text-color">Lanche da Manha e Tarde 🥪</label>
        <div className="food-container">
          {availableFoodsFrutas.map((food, index) => (
            <label key={index} className={`food-item flex w-[100%] justify-center items-center text-sm md:text-lg ${selectedFoods3.some((item) => item.foodd === food.foodd) ? 'selectedfood' : ''}`}>
              <input
                className="hidden"
                type="checkbox"
                value={food.food}
                checked={selectedFoods3.some((item) => item.foodd === food.foodd)}
                onChange={() =>
                  handleFoodSelectionFrutas(
                    selectedFoods3,
                    setSelectedFoods3,
                    food.food,
                    food.foodd
                  )
                }
                disabled={
                  selectedFoods3.length === 20 &&
                  !selectedFoods3.some((item) => item.foodd === food.foodd)
                }
              />
              <span className="checkmark">
                {food.food}
                {food.img}
              </span>
            </label>
          ))}
        </div>
        <div className="select-wrapper">
          {<select
            id="Chocolate"
            value={chocolate}
            onChange={(e) => setChocolate(e.target.value)}
          >
            <option value="">Sobremesa ?🍦</option>
            <option value="Não adicione chocolate na dieta">Não, obrigado</option>
            <option value="adicione apenas UM chocolate Bis na dieta">Sim, um Bis.🍫</option>
            <option value="adicione apenas UM chocolate Prestígio na dieta">Sim, um Prestígio.🍫</option>
            <option value="adicione apenas UM chocolate Trento na dieta">Sim, um Trento.🍫</option>
            <option value="adicione apenas UM chocolate Baton na dieta">Sim, um Baton.🍫</option>
            <option value="adicione apenas UM chocolate Chokito na dieta">Sim, um Chokito.🍫</option>
            <option value="adicione apenas UM chocolate Sonho de Valsa na dieta">Sim, um Sonho de Valsa.🍫</option>
          </select>

          }
        </div>
        
      </div>
      <div className="chat-inputs mx-auto">
      <div className="flex justify-center text-center">
        </div>
        <p className="p-0 m-0 mt-3 mx-auto text-[#000000] text-center text-xl">Conte-nos mais sobre o cliente 👋</p>
        <p className="p-0 m-0 mt-3 mx-auto text-[#909090] text-center text-xs">Clique abaixo para selecionar</p>
        <br></br>
        <div className="select-wrapper">
          <select
            id="exercicio"
            value={exercicio}
            onChange={(e) => setExercicio(e.target.value)}
          >
            <option value="1.0">Nível de Atividade Física</option>
            <option value="1.2">Sedentário (pouca ou nenhuma atividade física)</option>
            <option value="1.3">Levemente ativo (exercícios 1 a 3 vezes por semana)</option>
            <option value="1.5">Moderadamente ativo (exercícios de 3 a 5 vezes por semana)</option>
            <option value="1.7">Altamente ativo (exercícios de 5 a 7 dias por semana)</option>
            <option value="1.8"> Extremamente ativo (exercícios todos dias e faz trabalho braçal) </option>


          </select>
        </div>
        <div className="select-wrapper">
          <select
            id="horarios"
            value={horarios}
            onChange={(e) => setHorarios(e.target.value)}
          >
            <option value="">Horários de cada refeição</option>
            <option value="Sem Horarios">Tenho meu próprio horário</option>
            <option value="07:00, 10:00, 12:30, 15:30, 19:30">05:30, 08:30, 12:00, 15:00, 19:00</option>
            <option value="07:00, 10:00, 12:30, 15:30, 19:30">07:00, 10:00, 12:30, 15:30, 19:30</option>
            <option value="06:30, 09:30, 13:00, 16:00, 20:00">06:30, 09:30, 13:00, 16:00, 20:00</option>
            <option value="08:00, 11:00, 13:30, 16:30, 20:30">08:00, 11:00, 13:30, 16:30, 20:30</option>
            <option value="07:30, 10:30, 12:00, 15:00, 19:00">07:30, 10:30, 12:00, 15:00, 19:00</option>
            <option value="06:00, 09:00, 12:00, 15:00, 19:00">06:00, 09:00, 12:00, 15:00, 19:00 </option>
            <option value="09:00, 11:00, 13:00, 16:00, 20:00">09:00, 11:00, 13:00, 16:00, 21:00</option>

          </select>
        </div>
        <div className="select-wrapper">
          <select
            id="horarios"
            value={comorbidades}
            onChange={(e) => setComorbidades(e.target.value)}
          >
            <option value="Nenhum">Comorbidades paciente</option>
            <option value="Nenhuma">Sem Comorbidade</option>
            <option value="Diabete">Diabetes</option>
            <option value="Hipertensão">Hipertensão</option>
            <option value="Intolerância a Lactose">Intolerância a Lactose</option>

          </select>
        </div>
        </div>
      




      {userTickets === 0 ? (

        <div className="rounded-lg overflow-hidden bg-gradient-to-r from-green-600 to-green-700 p-6 shadow-xl">
          <div className="mb-4">
            <span className="inline-block bg-green-50 text-green-800 font-semibold p-2 rounded-lg text-lg shadow">
              Fale Conosco
            </span>
            <p className="text-white mt-4">
              Você ainda nao assinou/renovou nossos planos, favor entrar em contato.
            </p>
          </div>
          <button
            className="w-full bg-green-50 hover:bg-green-800 text-green-800 hover:text-green-50 py-3 px-4 rounded-md shadow-md transition-colors duration-300 ease-in-out font-bold"
            onClick={goToPlanos}>
            ENTRAR EM CONTATO
          </button>
        </div>


      ) : (
        <div>
          <button
  className={`py-3 px-4 my-3 rounded-md shadow-md transition-colors duration-300 ease-in-out font-bold ${
    userTickets === '0' 
    ? 'bg-gray-400 cursor-not-allowed'
    : 'bg-gradient-to-r from-green-500 to-green-600 text-white hover:from-green-700 hover:to-green-800'
  }`}
  disabled={userTickets === '0' }
  onClick={handleSubmit}>
  Montar Dieta Personalizada
</button>

          {error && (
            <div className="chat-error text-white bg-red-600 rounded-md mb-3">
              {error}
            </div>
          )}
        </div>
      )}
      <br></br>
      {recomend && (<div className="chat-response">
        <label htmlFor="response" className=" font-bold text-xl w-[90%] text-center">Dieta Personalizada</label>
        <textarea
          id="response"
          ref={resultTextareaRef}
          name="response"
          rows="20"
          value={answer}
          readOnly
        >
        </textarea>
      </div>)}
      <div className="chat-response">
        {recomend && (
          <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000
          }}>
            <div style={{
              backgroundColor: 'white',
              borderRadius: '10px',
              padding: '20px',
              maxWidth: '500px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              border: '1px solid #ddd',
              position: 'relative'
            }}>
              <div style={{
                position: 'absolute',
                top: '-25px',
                right: '20px',
                backgroundColor: '#FF4D61',
                color: 'white',
                borderRadius: '50%',
                width: '50px',
                height: '50px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '24px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)'
              }}>
                ⚠️ {/* Ícone de aviso */}
              </div>
              <h3 style={{
                textAlign: 'center',
                color: '#333',
                fontWeight: 'bold',
                marginBottom: '15px'
              }}>
                Dieta Gerada com Sucesso
              </h3>
              <p style={{
                textAlign: 'center',
                color: '#555',
                marginBottom: '20px'
              }}>
                Clique no botão abaixo para salvar sua dieta e visualizá-la. Depois você poderá baixar em PDF.
              </p>
              <div style={{ textAlign: 'center' }}>
                <button onClick={handleSave} style={{
                  backgroundColor: '#FF4D61',
                  color: 'white',
                  padding: '10px 20px',
                  borderRadius: '5px',
                  border: 'none',
                  cursor: 'pointer'
                }}>
                  SALVAR MINHA DIETA
                </button>
              </div>
            </div>
          </div>
        )}

        {/* {showError3 && !userDieta && (userTickets==='DietaNormal' || userTickets==='DietaTreino') && (
        <ErrorCard
          message="Por favor! Preencha suas informações gerar sua dieta"
          onClose={closeError3} // Fechar o card de erro quando o botão "OK" é clicado
        />
      )} */}

      </div>


      {isLoading && (
        <div className="overlay">
          <div className="Spinner">

            <div className="ComidaEmoji">🥦</div>

            <TailSpin
              color="#00dc25"
              height={80}
              width={80}
            />
            <p>Carregando ⏳...{loadingProgress}%</p>
            <p className="AvisoNaoSaia">Por favor, não saia da página enquanto geramos sua dieta 🥗.</p>
          </div>
        </div>
      )}

    </div>
  );
}

export default ChatContainer;