import React, { useEffect, useState, useCallback, useRef } from 'react';
import { UserAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import jsPDF from 'jspdf';
import ErrorCard from '../Error/ErrorCard';
import ErrorCard3 from '../Error/ErrorCard3';
import ErrorCard2 from '../Error/ErrorCard2';
import header from '../../img/Header.png'
import ReceitasPdf from './PDF/ReceitasPdf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import IMGRafaela from '../../img/Rafaela.jpeg'
import IMGReducao from '../../img/reeducao.jpeg'
import IMGMarcelo from '../../img/Marcelo.png'
import IMGLarissa from '../../img/IMGLarissa.jpeg'
import IMGTD from '../../img/TD.jpeg'
import IMGQuele from '../../img/Quele.jpeg'
import IMGNathany from '../../img/Nathanycirelli.jpeg'
import IMGLuCoelho from '../../img/LuCoelho.png'

export default function LocalDieta() {

  const [successMessageVisible, setSuccessMessageVisible] = useState(false)
  const [userDieta, setUserDieta] = useState(null);
  const [userTickets, setUserTickets] = useState(null);
  const [userAltura, setUserAltura] = useState(null);
  const [userPeso, setUserPeso] = useState(null);
  const [userImc, setUserImc] = useState('');
  const [aguaRecomendada, setAguaRecomendada] = useState(0);
  const [possuiTicket, setPossuiTicket] = useState(false);
  const [showError3, setShowError3] = useState(true);
  const [showErro2 , setShowError2] = useState(true)
  const [showError, setShowError] = useState(true);
  const [edicaoDieta, setEdicaoDieta] = useState(false)
  const [userTicketsUsados,setUserTicketsUsados] = useState(null)
  const [userNome, setUserNome] = useState("")
  const [mensagem, setMensagem] = useState('');
 
  const { user,retornaDieta, retornaDados, retornaTicket, retornaTicketUsado, adicionarDieta,retirarEdicaoDieta,retornaEdicaoDieta } = UserAuth();
  const [edit, setEdit] = useState(false)
  const resultTextareaRef = useRef(null);
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate();


  const calcularAguaNecessaria = useCallback(() => {
    const agua = userPeso ? 0.03 * userPeso : 0;
    setAguaRecomendada(agua);
  }, [userPeso]);
  useEffect(() => {
    calcularAguaNecessaria();
  }, [userPeso, calcularAguaNecessaria]);

  const handleSave = () => {
    adicionarDieta(resultTextareaRef.current.value);
    return;
  };


  const copyAnswer = () => {
    if (userDieta) {
      navigator.clipboard.writeText(userDieta)
        .then(() => {
          setSuccessMessageVisible(true);

          // Ocultar o mini card após 2 segundos (opcional)
          setTimeout(() => {
            setSuccessMessageVisible(false);
          }, 3000);
        })
        .catch(err => {
          console.error('Erro ao copiar para a área de transferência:', err);
        });
    }
  };

  const closeError = () => {
    setShowError(false);
  }


  const closeError3 = () => {
    setShowError3(false);
    Voltar()
  }

  const closeError2 = () => {
    setShowError2(false);
    
  }
  const Voltar = () => {
    navigate('/home');
  }

  
  useEffect(() => {
    // Simula um tempo de carregamento de 3 segundos
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    // Limpa o timer quando o componente é desmontado
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    
    retornaDados()
      .then((array) => {
        if (array !== null) {
          setUserNome(array[3] === undefined ? null : array[3])
          setUserPeso(array[0]);
          setUserAltura(array[1]);
          const imcCalculado = (array[0] / (array[1] * array[1])).toFixed(1);
          setUserImc(imcCalculado);
          if (imcCalculado < 18.5) {
            setMensagem("Você está abaixo do peso");
          } else if (imcCalculado >= 18.5 && imcCalculado <= 24.9) {
            setMensagem("Você está com peso normal");
          } else if (imcCalculado >= 25 && imcCalculado <= 29.9) {
            setMensagem("Você está com sobrepeso");
          } else if (imcCalculado >= 30) {
            setMensagem("Você está com obesidade");
          } else {
            setMensagem("Por favor, atualize seus dados de peso e altura.");
          }
        } else {
          console.log('Sem dados ainda');
        }
      })
      .catch((error) => {
        console.error('Erro ao obter os dados:', error);
      });
  }, [retornaDados]);
 
  useEffect(() => {
    retornaDieta()
      .then(async (dieta) => {
        if (dieta !== null) {
           setUserDieta(dieta);
        } else {
          setUserDieta('');
        }
        
      })
      .catch((error) => {
        console.error('Erro ao obter os tickets:', error);
        setUserDieta('Erro ao obter os tickets');
      });

    retornaTicket()
      .then((tickets) => {
        if (tickets !== null) {
          setUserTickets(tickets);
          if (tickets > 0) {
            setPossuiTicket(true)
          }

        } else {
          setUserTickets("Nenhum ticket disponível");
        }

      })
      .catch((error) => {
        setUserTickets("Erro ao obter os tickets");
      });

    retornaTicketUsado()
      .then((tickets) => {
        if (tickets !== null) {
          setUserTicketsUsados(tickets);

        } else {
          console.log("Nenhum ticket disponível", userTicketsUsados);
        }

      })
      .catch((error) => {
        console.log("Erro ao obter os tickets");
      });
     
  retornaEdicaoDieta() 
      .then((edicaoDieta) => {
        if (edicaoDieta === null) {
          setEdicaoDieta(true);
        } else if (edicaoDieta !== null) {
          setEdicaoDieta(edicaoDieta);
        
        }else{
          console.log("Sem disponível");
        }

      })
      .catch((error) => {
        console.log("Erro ao obter os tickets");
      });

  }, [retornaDieta, retornaTicket, possuiTicket, userTickets,retornaTicketUsado, userTicketsUsados, retornaEdicaoDieta, userDieta]);



  
  const handleDownloadPDF = () => {
    if (!userDieta) {
      return;
    }
    const pdf = new jsPDF();
    const margin = 10;
    const pageWidth = pdf.internal.pageSize.width;
    const pageHeight = pdf.internal.pageSize.height - 1 * 8;
    const fontSize = 12;
    const dado = `${userNome ? `\nNome: ${userNome}` : ``}\nAltura: ${userAltura}\nPeso: ${userPeso}\nIMC: ${userImc}\n${mensagem}\nQuantidade de Água Mínima Recomendada: ${aguaRecomendada.toFixed(2)}L\n`

    const splitText = pdf.splitTextToSize(`\nDieta Totalmente Personalizada e Exclusiva\nAconselhável Trocar a Dieta Depois de 20 Dias\n${dado}\n${userDieta}`, pageWidth - margin * 2);

    var headerHeight =  49;
    var marginLeft = (pageWidth - 80) / 2
    pdfimage(user.email)
   function pdfimage(email){
   switch(email) {
      case "raphaelamoutinho1@gmail.com":
        return pdf.addImage(IMGRafaela, 'JPEG', marginLeft, -8, 70, 70) ;
      case "hbruna978@gmail.com":
        return (pdf.addImage(IMGReducao, 'PNG', marginLeft+10 , 2, 60, headerHeight)) ;
      case "otaviocrs@gmail.com":
      return headerHeight = 10;
      case "laravimiranda03@gmail.com":
      return (pdf.addImage(IMGLarissa, 'PNG', marginLeft+10 , 2, 50, 50))
      case "ticiano28@hotmail.com":
      return (pdf.addImage(IMGTD, 'PNG', marginLeft+15 , 2, 50, 40))
      case "quelegurgel@gmail.com":
      return (pdf.addImage(IMGQuele, 'PNG', marginLeft+10 , -8, 60, 60))
      case "mntreino@gmail.com":
      return pdf.addImage(IMGMarcelo, 'PNG', marginLeft+12, 10, 50, 30) ;
      case "nathanycirelli@gmail.com":
      return pdf.addImage(IMGNathany, 'PNG', marginLeft+25, 8, 30, 30) ;
      case "Laudoslu2@gmail.com":
        return pdf.addImage(IMGLuCoelho, 'PNG', marginLeft + 10, 8, 60, 30) ;
      default:
      return pdf.addImage(header, 'PNG', 0, 0, pageWidth, headerHeight)
    
    }
  }

  function colorText(email){
    switch(email){
      case 'raphaelamoutinho1@gmail.com' : return pdf.setTextColor(179, 34, 93); 
      case 'mntreino@gmail.com' : return pdf.setTextColor(245, 134, 52) 
      case 'laravimiranda03@gmail.com' : return pdf.setTextColor(150, 150, 200)
      case 'ticiano28@hotmail.com' : return pdf.setTextColor(56,46,77) 
      case 'quelegurgel@gmail.com' : return pdf.setTextColor(206,35,43) 
      case 'nathanycirelli@gmail.com' : return pdf.setTextColor(60,60,60)
      case 'Laudoslu2@gmail.com' : return pdf.setTextColor(255,0,0)  
      default:  
      return pdf.setTextColor(50, 205, 50) ;

    }
  }

  function colorRodape(email){
    switch(email){
      case 'raphaelamoutinho1@gmail.com' : return pdf.setFillColor(179, 34, 93); 
      case 'mntreino@gmail.com' : return pdf.setFillColor(245, 134, 52)
      case 'laravimiranda03@gmail.com' : return pdf.setFillColor(150, 150, 200)
      case 'ticiano28@hotmail.com' : return pdf.setFillColor(20,4,59) 
      case 'quelegurgel@gmail.com' : return pdf.setFillColor(206,35,43)
      case 'nathanycirelli@gmail.com' : return pdf.setFillColor(60,60,60) 
      case 'Laudoslu2@gmail.com' : return pdf.setFillColor(255,0,0) 
      default:  
      return pdf.setFillColor('#1C9E22') ;

    }
  }


    let yPosition = margin + headerHeight - 8; 

    for (let i = 0; i < splitText.length; i++) {
      if (yPosition + 5 > pageHeight) {
        pdf.addPage();
        yPosition = margin;
      }

      pdf.setFontSize(fontSize);

      if (splitText[i].includes('Dieta Totalmente Personalizada e Exclusiva')) {
        pdf.setFontSize(16)
      } else {
        pdf.setTextColor(0);
      }

      if (splitText[i].includes('Café da Manhã') ||
        splitText[i].includes('Lanche da Manhã') ||
        splitText[i].includes('Café da manhã') ||
        splitText[i].includes('Lanche da manhã') ||
        splitText[i].includes('Almoço') ||
        splitText[i].includes('Lanche da Tarde') ||
        splitText[i].includes('Lanche da tarde') ||
        splitText[i].includes('Dieta Totalmente Personalizada e Exclusiva') ||
        splitText[i].includes('Janta')) {
        colorText(user.email)  // Cor azul 
      } else {
        pdf.setTextColor(0); // Volta à cor padrão do texto (preto)
      }

      pdf.text(margin, yPosition, splitText[i]);

      yPosition += fontSize - 2;
    }


    // Rodapé moderno
   // Cor de fundo verde
    colorRodape(user.email)
    pdf.rect(0, pageHeight - 10, pageWidth, 30, 'F');
    pdf.setFontSize(12); // Ajusta o tamanho da fonte
    pdf.setTextColor('#ffffff'); // Texto branco

    // Centraliza o texto no rodapé
    let footerText = user.email==='raphaelamoutinho1@gmail.com' || user.email==='hbruna978@gmail.com' || user.email==='mntreino@gmail.com' || user.email==='Laudoslu2@gmail.com' ?
    `Caso tenha alguma dúvida, entre em contato. Eduarda Lemos - CRN:23104001
    ` : 'Caso tenha alguma dúvida, entre em contato.';
    let textWidth = pdf.getTextWidth(footerText);
    pdf.text((pageWidth - textWidth) / 2, pageHeight , footerText);

    const fileName = 'Nutri Inteligente.pdf';
    pdf.save(fileName);
};

  function shouldApplyBold(line) {
    const palavrasChave = ['Opção' || 'opção', 'Café da Manhã' , 'Café da manhã', 'Lanche da Manhã' , 'Lanche da manhã', 'Almoço' , 'almoço', 'Lanche da Tarde' , 'Lanche da tarde', 'Janta' , 'Jantar', "Treino", "Segunda-Feira", "Terça-Feira", "Quarta-Feira", "Quinta-Feira", "Sexta-Feira", "Sábado", "Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira",];
    return palavrasChave.some(palavra => line.includes(palavra));
  }
  const dietaDividida = userDieta ? userDieta.split(/[\n]+/) : [];

  const opcao1Index = dietaDividida.findIndex(line =>
    line.includes('Opção 1') || line.includes('opção 1') 
  );
  
  const opcao2Index = dietaDividida.findIndex(line =>
    line.includes('Opção 2') || line.includes('opção 2') 
  );
  
  const cafeDaManhaIndex = dietaDividida.findIndex(line =>
    line.includes('Café da Manhã') || line.includes('Café da manhã') || line.includes('CAFÉ DA MANHÃ')
  );

  const lancheDaManhaIndex = dietaDividida.findIndex(line =>
    line.includes('Lanche da Manhã') || line.includes('Lanche da manhã') || line.includes('LANCHE DA MANHÃ')
  );

  const almocoIndex = dietaDividida.findIndex(line =>
    line.includes('Almoço') || line.includes('almoço') || line.includes('ALMOÇO')
  );

  const lancheDaTardeIndex = dietaDividida.findIndex(line =>
    line.includes('Lanche da Tarde') || line.includes('Lanche da tarde') || line.includes('LANCHE DA TARDE')
  );

  const jantaIndex = dietaDividida.findIndex(line =>
    line.includes('Janta') || line.includes('Jantar') || line.includes('JANTAR') || line.includes('JANTA')
  );

  const treinoIndex = dietaDividida.findIndex(line =>
    line.includes('Treino') || line.includes('treino')
  );

  const inicioOpcao = dietaDividida.slice(0, opcao1Index)
  const opcao1 = dietaDividida.slice(opcao1Index, opcao2Index)
  const opcaa2 = dietaDividida.slice(opcao2Index, treinoIndex.length === -1 ? "": treinoIndex )

  const inicio = dietaDividida.slice(0, cafeDaManhaIndex)
  const cafeDaManha = dietaDividida.slice(cafeDaManhaIndex, lancheDaManhaIndex);
  const lancheDaManha = dietaDividida.slice(lancheDaManhaIndex, almocoIndex);
  const almoco = dietaDividida.slice(almocoIndex, lancheDaTardeIndex);
  const lancheDaTarde = dietaDividida.slice(lancheDaTardeIndex, jantaIndex);
  const janta = dietaDividida.slice(jantaIndex, treinoIndex ? treinoIndex : "");
  const treino = dietaDividida.slice(treinoIndex ? treinoIndex : "");
  const [dietaEditavel, setDietaEditavel] = useState(dietaDividida);


    const handleChange = (event) => {
      const newValue = event.target.value.split('\n');
      setDietaEditavel(newValue);
  };

  return (
    <div className='flex flex-col items-center p-4 md:w-4/5 mx-auto'>

       {loading ? (
      <div className="flex justify-center items-center h-screen">
      <FontAwesomeIcon icon={faSpinner} color='#17A34A' spin size="3x" />
    </div>
    ) : (
      <div className='w-full'>
   
        <div className='bg-white p-4 rounded-2xl shadow-lg lg:w-3/5 lg:mx-auto'>
        <div className='flex justify-between items-center mb-2'>
        
           
    </div>

        <div className='my-1 flex justify-between mb-2'>
          <button className='bg-red-500 hover:bg-red-700 text-white font-bold  w-[110px] h-[60px] rounded mx-auto' onClick={handleDownloadPDF}
          >
            Baixar 📥
          </button>

          <button className='bg-green-500 hover:bg-green-700 text-white font-bold w-[110px] h-[60px] rounded mx-auto' onClick={copyAnswer}
            >
            Copiar 📋
          </button>
        </div>
   
      <div className='flex flex-column md:w-4/5 mx-auto'>
      {showError3 && !userDieta && possuiTicket && (
        <ErrorCard2
          message="Você tem saldo disponiveis. Clique em 'Usar Tickets' abaixo para preencher suas informações e gerar sua dieta"
          onClose={closeError3} // Fechar o card de erro quando o botão "OK" é clicado
        />
      )}

      {showErro2 && userDieta && (
        <ErrorCard3
          message="Acesse o site pelo navegador Safari ou Chrome para baixar o PDF da sua dieta"
          onClose={closeError2} // Fechar o card de erro quando o botão "OK" é clicado
        />
      )}

      {showError && !userDieta && !possuiTicket && (
        <ErrorCard
          id="error1" // Defina um ID único para este card  
          message="Você ainda não tem saldo para gerar sua dieta. Por favor, realize o pagamento para ter a sua dieta gerada. Caso já tenha pago, entre em contato."
          onClose={closeError} // Use a função closeError para fechar este card
        />
      )}
    
      </div>
      </div>
      

      {successMessageVisible && (
  <div className="chat-error text-center bg-red-600 text-white py-3 px-4 mx-auto w-[90%] mt-4 rounded-lg shadow-lg">
    Dieta copiada com sucesso 🎯 Agora só colar 📄
  </div>
)}

{showError3 && !userDieta && possuiTicket && (
        <ErrorCard2
          message="Oba! Você tem saldo disponível. Clique em 'Usar Tickets' abaixo para preencher suas informações"
          onClose={closeError3}
        />
      )}

  
      {showError && !userDieta && !possuiTicket && (
        <ErrorCard
          id="error1"
          message="Você ainda não tem saldo para gerar sua dieta. Por favor, realize o pagamento para ter a sua dieta gerada. Caso já tenha pago, entre em contato."
          onClose={closeError}
        />
      )}
 {edit === true && (
          <div>
      <div className='flex items-center justify-around'>
      <button
        className="my-4 hover:bg-red-600 text-white mx-auto px-6 py-3 rounded-lg bg-red-600 transition duration-300 ease-in-out"
        onClick={() => {
          handleSave()
          setEdit(!edit)
          retirarEdicaoDieta()
          window.location.reload()
         }}
      >
        Salvar 
      </button>

<button
className="my-4 hover:bg-red-600 text-white mx-auto px-6 py-3 rounded-lg bg-red-600 transition duration-300 ease-in-out"
onClick={() => {setEdit(!edit)
  setDietaEditavel(dietaDividida)}}
>
Cancelar 
</button>
</div> 

</div>
        )}
      {userDieta && (<div>
        {edit === false ? ( 
         <div className='mb-3'>
         {opcao1Index < cafeDaManhaIndex ? (<div>
         {inicioOpcao.map((line) => line).length !== 0 && <div className='bg-green-500 font-bold text-xl text-center pb-2 mt-4 shadow-xl rounded-xl'>
         {inicioOpcao.map((line, index) => (
           <p className='p-1 m-0' key={index} style={{ color: 'white' }}>{line}</p>
         ))}
         </div>}
         {opcao1.map((line) => line).length !== 0 && <div className='bg-green-500 text-xl text-center pb-2 mt-4 shadow-xl rounded-xl'>
         {opcao1.map((line, index) => (
           <p className='p-1 m-0' key={index} style={{ color: 'white' }}> {shouldApplyBold(line) ? (
             <>
     <br />
     <strong className='my-2'>{line}</strong>
     <br />
     
   </>
           )
            : (
             line
           )}</p>
         ))}
         </div>}
         {opcaa2.map((line) => line).length !== 0 && <div className='bg-green-500  text-xl text-center pb-2 mt-4 shadow-xl rounded-xl'>
         {opcaa2.map((line, index) => (
           <p className='p-1 m-0' key={index} style={{ color: 'white' }}> {shouldApplyBold(line) ? (
             <>
             <br />
             <strong className='my-2'>{line}</strong>
             <br />
           </>
             ) : (
               line
             )}</p>
         ))}
         </div>}
         {treino.map((line) => line).length !== 0 && <div className='bg-gray-800 mt-4 py-2 shadow-xl rounded-xl'>
         {treino.map((line, index) => (
           <p className='p-2 m-0' key={index} style={{ color: 'white' }}>
             {shouldApplyBold(line) ? (
               <strong className='my-2'>{line}</strong>
             ) : (
               line
             )}
           </p>
         ))}
         
       </div>}
         </div>
         
       ) : ( <div className='mb-3'>
       {inicio.map((line) => line).length !== 0 && <div className='bg-green-500 font-bold text-xl text-center pb-2 mt-4 shadow-xl rounded-xl'>
         {inicio.map((line, index) => (
           <p className='p-1 m-0' key={index} style={{ color: 'white' }}> {shouldApplyBold(line) ? (
             <strong className='my-2'>{line}</strong>
           ) : (
             line
           )}</p>
         ))}
       </div>
       }
       <div className='bg-green-500 mt-4 py-2 shadow-xl rounded-xl'>
         {cafeDaManha.map((line, index) => (
           <p className='p-2 m-0' key={index} style={{ color: 'white' }}>
             {shouldApplyBold(line) ? (
               <strong className='my-2'>{line}</strong>
             ) : (
               line
             )}
           </p>
         ))}
       </div>
       <div className='bg-green-500 mt-5 py-2 shadow-xl rounded-xl' >
         {lancheDaManha.map((line, index) => (
           <p className=' p-2 m-0' key={index} style={{ color: 'white' }}>
             {shouldApplyBold(line) ? (
               <strong className='my-2'>{line}</strong>
             ) : (
               line
             )}
           </p>
         ))}
       </div>
       <div className='bg-green-500 mt-5 py-2 shadow-xl rounded-xl' >
         {almoco.map((line, index) => (
           <p className='p-2 m-0' key={index} style={{ color: 'white' }}>
             {shouldApplyBold(line) ? (
               <strong className='my-2'>{line}</strong>
             ) : (
               line
             )}
           </p>
         ))}
       </div>
       <div className='bg-green-500 mt-5 py-2 shadow-xl rounded-xl'>
         {lancheDaTarde.map((line, index) => (
           <p className='p-2 m-0 rounde' key={index} style={{ color: 'white' }}>
             {shouldApplyBold(line) ? (
               <strong className='my-2'>{line}</strong>
             ) : (
               line
             )}
           </p>
         ))}
       </div>
       <div className='bg-green-500 mt-5 py-2 shadow-xl rounded-xl'>
         {janta.map((line, index) => (
           <p className='p-2 m-0' key={index} style={{ color: 'white' }}>
             {shouldApplyBold(line) ? (
               <strong className='my-2'>{line}</strong>
             ) : (
               line
             )}
           </p>
         ))}
       </div>
       <div className='bg-gray-800 mt-4 py-2 shadow-xl rounded-xl'>
         {treino.map((line, index) => (
           <p className='p-2 m-0' key={index} style={{ color: 'white' }}>
             {shouldApplyBold(line) ? (
               <strong className='my-2'>{line}</strong>
             ) : (
               line
             )}
           </p>
         ))}
         
       </div>
       </div>)}
     </div> ) : 
      (
        <div className='flex flex-col items-center'>
          <h1>Modo Edição de Dieta</h1>
          <textarea
        className='bg-white w-full text-black p-4 shadow-xl rounded-xl'
        style={{ lineHeight: '2.5' }}
            ref={resultTextareaRef}
                rows={dietaEditavel.length+5}
                value={dietaEditavel.join('\n')}
                onChange={handleChange}
                
            >
            </textarea>
        </div>
    )}
      

  <div className='flex flex-col items-center justify-around rounded-2xl p-3 shadow-lg bg-white mt-3 lg:w-1/2 lg:mx-auto'>   
   <ReceitasPdf></ReceitasPdf>
  
    <div className='flex justify-around w-full'>
    {edit === false && edicaoDieta === true && (
      <button
        className="mt-2 hover:bg-blue-600 text-white mx-auto px-6 py-3 rounded-lg bg-blue-600 transition duration-300 ease-in-out"
        onClick={() => {
          setEdit(!edit)
          setDietaEditavel(dietaDividida)
          window.scrollTo(0, 0)
        }}
      >
        Editar 
      </button>
      )}
    <button
        className="mt-2 hover:bg-blue-600 text-white mx-auto px-6 py-3 rounded-lg bg-blue-600 transition duration-300 ease-in-out"
        onClick={() => {
          navigate('/home')
        }}
      >
        Inicio 
      </button>
    </div>
     
  </div>
       
      </div> 
      )}
    </div>

)}
</div>
  )}


