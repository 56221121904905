import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './HamburgerMenu.module.css';
import { useNavigate } from 'react-router-dom';
import { UserAuth } from '../../context/AuthContext';
import ImageMenu from '../../img/PerfilMenu-removebg-preview.png';
import ImageExit from '../../img/IMG-exit.png'
import { RiProfileLine, RiQuestionAnswerLine, RiSurveyFill, RiSurveyLine } from "react-icons/ri";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

function HamburgerMenu({ isOpen, toggleMenu }) {
  const { user, logout, retornaTicket, atendimentoNutricionista } = UserAuth();
  const [userTickets, setUserTickets] = useState(null);
  const navigate = useNavigate();
 
  const ContatoSuporte = () => {
    navigate('/suporte')
  }


  const LocalDiet = () => {
    navigate('/dieta')
  }

  const SobreMim = () => {
    navigate('/sobre')
  }

  const PageTreino = () => {
    navigate('/montartreino')
  }

  const PageLocalTreino =()=>{
    navigate('/treino')
  }


  useEffect(() => {

    retornaTicket()
      .then(async (tickets) => {
        if (tickets !== null) {
          await setUserTickets(tickets);
        } else {
          setUserTickets('Nenhum ticket disponível');
        }

      })
      .catch((error) => {
        setUserTickets('Erro ao obter os tickets');
      })
  }, [retornaTicket, atendimentoNutricionista]);


  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (e) {
    }
  };



  return (
    <div >
      <div className={styles['menu-toggle']} onClick={toggleMenu}>
        <FontAwesomeIcon icon={faBars} size='2x'  className='text-[#fff] m-0 p-0'></FontAwesomeIcon>
        </div>
      {isOpen && (
        <div className={`${styles['hamburger-menu']} ${isOpen ? styles.open : ''} w-[80%] sm:w-2/4 lg:w-2/6`} style={{ background: 'transparent' }}>
          <div className={`${styles['close-button']}`} onClick={toggleMenu}>
            X
          </div>

          <nav className=''>
            <ul className=' my-0 flex flex-col justify-start lg:justify-start shadow-2xl bg-[#fff] rounded-lg items-center text-sm p-0'>

              {/* <img src={ImageMenu} className='w-[180px] h-[160px] lg:w-[120px] lg:h-[100px] p-0 m-0' alt="" /> */}

              <div className='flex flex-col justify-around w-full  '>
                <li className='border-bottom'>
                  <div className='flex items-center justify-evenly'>
                  <div className=' rounded-full '>
                  <img src={ImageMenu} className='w-[70px] h-[120px] p-0 m-0' alt="" />
                  </div>
                <div className='h-[90px] flex flex-col py-2 items-center justify-start text-left'>
                  <h4 className='text-black w-full text-left'>Olá, Profissional 🍀</h4>
                    <p className='text-black w-full p-0 mb-1 text-left m-0'>{user && user.email}</p>
                    <p className='text-black w-full p-0 text-left m-0'>Ticket: {userTickets}</p>
                  </div>
                  </div>
                </li>
                <li className='border-bottom'>
                  <button className="flex text-sm md:text-base items-center text-black w-full h-[50px] lg:h-[40px]  " onClick={SobreMim}>
                    <RiProfileLine className='w-[25px] h-[25px] mx-4'></RiProfileLine> Evolução
                  </button>
              
                  <button className="flex text-sm md:text-base items-center text-black  w-full  h-[50px] lg:h-[40px]  " onClick={LocalDiet}>
                    <RiSurveyLine className='w-[25px] h-[25px] mx-4'></RiSurveyLine> Acessar Dieta
                  </button>
               
                  <button className="flex text-sm md:text-base items-center text-center text-black w-full  h-[50px] lg:h-[40px] " onClick={ContatoSuporte}>
                    <RiQuestionAnswerLine className='w-[25px] h-[25px] mx-4'></RiQuestionAnswerLine> Fale conosco
                  </button>
                 
                  <button className="flex text-sm md:text-base items-center text-center text-black w-full  h-[50px] lg:h-[40px] " onClick={PageTreino}>
                    <RiSurveyFill className='w-[25px] h-[25px] mx-4'></RiSurveyFill> Montar Treino
                  </button>

                  <button className="flex text-sm md:text-base items-center text-center text-black w-full  h-[50px] lg:h-[40px] " onClick={PageLocalTreino}>
                    <RiSurveyLine className='w-[25px] h-[25px] mx-4'></RiSurveyLine> Acessar Treino
                  </button>
                  
              
              </li>
              <li>
              <button className='flex items-center text-sm  text-black h-[60px]  w-full' onClick={handleLogout}>
                <img src={ImageExit} className="w-[25px] h-[25px]  mx-4" alt="" /> Sair
            
              </button>
              </li>
              </div>
            </ul>
          </nav>

        </div>
      )}
    </div>
  );

}

HamburgerMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
};

export default HamburgerMenu;
