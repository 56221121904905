import React, { useEffect, useState } from 'react';
import '../Styles/App.css';
import HamburgerMenu from '../Menu/HamburgerMenu';
import ChatContainer from '../ChatContainer/ChatContainer';
import 'bootstrap/dist/css/bootstrap.css';
import axios from 'axios'
function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState(false);


  const [answer, setAnswer] = useState([]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };





  const sendRequestTreinoF = async (data) => {

    try {
      const result = await axios.post(`${process.env.REACT_APP_API_URL}/GerarDietaTreinoF`, { data: data });
      setAnswer(result.data.answer);
    } catch (error) {
      console.error('Erro ao enviar a requisição:', error);
    }
  };


  const sendRequestTreinoM = async (data) => {
    try {
      const result = await axios.post(`${process.env.REACT_APP_API_URL}/GerarDietaTreinoM`, { data: data });
      setAnswer(result.data.answer);
    } catch (error) {
      console.error('Erro ao enviar a requisição:', error);
    }
  };


  const sendRequest = async (data) => {
    try {
      const result = await axios.post(`${process.env.REACT_APP_API_URL}/GerarDieta`, { data: data });
      setAnswer(result.data.answer);
    } catch (error) {
      console.error('Erro ao enviar a requisição:', error);
    }
  };
  // Exemplo de uso:

  return (
    <div className="App pb-5">
      <header className="App-header p-3">
        {/* Adicione o componente HamburgerMenu e lógica de alternância aqui */}
        <HamburgerMenu isOpen={isMenuOpen} toggleMenu={toggleMenu} />
      </header>
      <br></br>
      <main className="App-main">
        <ChatContainer sendRequest={sendRequest} sendRequestTreinoF={sendRequestTreinoF} sendRequestTreinoM={sendRequestTreinoM} answer={answer} />
      </main>
    <div>
      </div>
    </div>
  );
}

export default Home;