import React, { useEffect, useState, useCallback } from 'react';
import { UserAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Chart } from 'react-chartjs-2';
import 'chart.js/auto';
import '../InfoUsuario/SobreMim.css';
import { IMaskInput } from 'react-imask';
import '../InfoUsuario/SobreMim.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const overlayStyle = {
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.9)', // Fundo escurecido
  zIndex: '999', // Garante que fique atrás do cardStyle
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};
const cardStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '300px',
  backgroundColor: '#fff',
  border: '1px solid #ddd',
  borderRadius: '10px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  zIndex: 1000,
  padding: '20px',
  textAlign: 'center',
};

const closeButtonStyle = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  background: 'none',
  border: 'none',
  color: '#aaa',
  cursor: 'pointer',
};

const options = {
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        callback: (value) => (value % 1 === 0 ? value + ' ' : value),
        font: {
          size: 14, // Font size for ticks
        }
      },
      grid: {
        color: 'rgba(200, 200, 200, 0.3)', // Lighter grid lines
      }
    },
  },
  plugins: {
    title: {
      display: true,
      text: 'Recomendações De Água',
      font: {
        size: 20,
        family: 'Helvetica, Arial, sans-serif', // Modern font for the title
        weight: 'bold',
      },
    },
    legend: {
      display: false,
    },
    tooltip: {
      enabled: true,
      bodyFont: {
        size: 16,
      },
      backgroundColor: 'rgba(0, 0, 0, 0.7)', // Tooltips with higher contrast
    },
  },
  animations: {
    tension: {
      duration: 1000,
      easing: 'linear',
      from: 1,
      to: 0,
      loop: true,
    },
  },
  elements: {
    bar: {
      backgroundColor: 'rgba(54, 162, 235, 1)',
      borderColor: 'rgba(54, 162, 235, 0.8)',
      borderWidth: 2,
      borderRadius: 20, // Rounded corners
      borderSkipped: false,
    },
  },
};

const AguaChart = ({ aguaRecomendada }) => {
  const data = {
    labels: ['Água (litros)'],
    datasets: [
      {
        label: 'Água Recomendada',
        data: [aguaRecomendada],
        backgroundColor: 'linear-gradient(180deg, rgba(54, 162, 235, 0.8) 0%, rgba(54, 162, 235, 0.6) 100%)', // Gradient effect
        borderWidth: 1,
      },
    ],
  };

  return <Chart type="bar" data={data} options={options} />;
};


export default function SobreMim() {
  const [userAltura, setUserAltura] = useState(0);
  const [userPeso, setUserPeso] = useState(0);
  const [userImc, setUserImc] = useState(0);
  const [userAge, setUserAge] = useState('');
  const [genero, setGenero] = useState('');
  const [aguaRecomendada, setAguaRecomendada] = useState(0);
  const [mensagem, setMensagem] = useState('');
  const [msgSave, setMsgSave] = useState('');
  const [alteracao, setAlteracao] = useState(false);
  const [labelAlterar, setLabelAlterar] = useState('Atualizar Dados');

  const { retornaDados, salvarDados } = UserAuth();
  const navigate = useNavigate();
  const handlerDietas = () =>{
    navigate('/dieta')
  }

  const calcularAguaNecessaria = useCallback(() => {
    const agua = userPeso ? 0.03 * userPeso : 0;
    setAguaRecomendada(agua);
  }, [userPeso]);

  useEffect(() => {
    retornaDados()
      .then((array) => {
        if (array !== null) {
          setUserPeso(array[0]);
          setUserAltura(array[1]);
          const imcCalculado = array[0] / (array[1] * array[1]);
          setUserImc(imcCalculado.toFixed(1));
          if (imcCalculado < 18.5) {
            setMensagem("Você está abaixo do peso");
          } else if (imcCalculado >= 18.5 && imcCalculado <= 24.9) {
            setMensagem("Você está com peso normal");
          } else if (imcCalculado >= 25 && imcCalculado <= 29.9) {
            setMensagem("Você está com sobrepeso");
          } else if (imcCalculado >= 30) {
            setMensagem("Você está com obesidade");
          } else {
            setMensagem("Por favor, atualize seus dados de peso e altura.");
          }
        } else {
          console.log('Sem dados ainda');
        }
      })
      .catch((error) => {
        console.error('Erro ao obter os dados:', error);
      });
  }, [retornaDados]);

  useEffect(() => {
    calcularAguaNecessaria();
  }, [userPeso, calcularAguaNecessaria]);

  const handleAlterar = () => {
    setAlteracao(!alteracao);
    setLabelAlterar("Alterar Dados");
    setMsgSave("");
  };

  const handleFechar = () => {
    setAlteracao(!alteracao);
  }

  const handleSalvar = async () => {
    const imc = userPeso / (userAltura * userAltura);
    try {
      await salvarDados(userPeso, userAltura, parseInt(imc));
    } catch {
    } finally {
      setMsgSave("Dados Salvos com Sucesso!");
      navigate(window.location.pathname);
    }
  };

  return (
  <div className='sobre-mim-container'>
    <h1 className='title-perfil'>Informações 👤 </h1>
    <div className="grid-container">
      <div className='card'>
        <h3 className='card-title'>Peso:</h3>
        <h2 className='card-value'>{userPeso} kg</h2>
      </div>

      <div className='card'>
        <h3 className='card-title'>Altura:</h3>
        <h2 className='card-value'>{userAltura} m</h2>
      </div>

      <div className='card'>
        <h3 className='card-title'>IMC:</h3>
        <h2 className='card-value'>{userImc}</h2>
        <p className='imc-message'>{mensagem}</p>
      </div>


      <div className='card flex-col'>
        <h3 className='card-title'>Recomendações:</h3>
        <h2 className='card-value'>{aguaRecomendada}L/dia</h2>
      </div>
    </div>
      <AguaChart aguaRecomendada={aguaRecomendada} />

      {alteracao && (
        <div style={overlayStyle}>
          <div style={cardStyle}>
          <button onClick={handleFechar} style={closeButtonStyle}>
                <FontAwesomeIcon icon={faTimesCircle} />
            </button>
        <div >
          <div className='form-field'>
            <label htmlFor="weight">Peso (kg)</label>
            <input
              type="number"
              id="weight"
              maxLength={4}
              value={userPeso}
              onChange={(e) => setUserPeso(e.target.value)}
              placeholder="Digite seu Peso"
            />
          </div>
          <div className='form-field'>
            <label htmlFor="height">Altura (m)</label>
            <IMaskInput
              mask="0.00"
              type="number"
              id="height"
              maxLength={4}
              value={userAltura}
              onChange={(e) => setUserAltura(e.target.value)}
              placeholder="Digite sua Altura: Ex: 1.69"
            />
          </div>
          <div className='form-field'>
            <label htmlFor="age">Idade</label>
            <input
              type="number"
              id="age"
              value={userAge}
              onChange={(e) => setUserAge(e.target.value)}
              placeholder="Digite sua Idade"
            />
          </div>
          <div className='form-field'>
            <label htmlFor="Genero">Gênero</label>
            <select
              id="genero"
              value={genero}
              onChange={(e) => setGenero(e.target.value)}
            >
              <option value="">Informe seu gênero</option>
              <option value="Masculino">Masculino</option>
              <option value="Feminino">Feminino</option>
            </select>
            <button className='button mt-3' onClick={handleSalvar}>SALVAR</button>
            {msgSave && (<div className='success-message'>{msgSave}</div>)}
          </div>
        </div>
        </div>
        </div>
      )}
     <div className="flex justify-center space-x-4 mt-4">
      <button className="button" onClick={handleAlterar}>{labelAlterar}</button>
      <button className="button" onClick={handlerDietas}>Minhas Dietas</button>
    </div>
    {msgSave && <div className='success-message'>{msgSave}</div>}
  </div>
);
}
