import { Link, useNavigate } from 'react-router-dom';
import Suporte2 from './../../img/suportCorrect.png';
import { FaWhatsapp, FaInstagram, FaTiktok } from 'react-icons/fa';

const linkStyle = {
  textDecoration: 'none',
};

const Suporte = () => {
  const navigate = useNavigate();

  const Voltar = () => {
    navigate('/home');
  };

  return (
    <div className="w-full flex flex-col items-center p-6 bg-gray-100 rounded-md shadow-md">
      <img src={Suporte2} alt="Imagem de Emagrecimento" className="w-[150px] h-[150px] my-5" />

      <p className="text-lg text-center text-gray-700 mb-6">
        Está precisando falar conosco? Por favor, escolha um dos canais abaixo:
      </p>

      <Link to={"https://api.whatsapp.com/send?phone=5524999375062"} style={linkStyle}>
        <button className="text-white px-10 py-3 rounded-lg bg-green-500 hover:bg-green-600 flex items-center">
          <FaWhatsapp className="mr-2" />
          Atendimento 1
        </button>
      </Link>
      <Link to={"https://api.whatsapp.com/send?phone=5524992178767"} style={linkStyle}>
        <button className="mt-5 mx-auto text-white px-10 py-3 rounded-lg bg-green-500 hover:bg-green-600 flex items-center">
          <FaWhatsapp className="mr-2" />
          Atendimento 2
        </button>
      </Link>
      <Link to={"https://instagram.com/nutriinteligenteoficial?igshid=OGQ5ZDc2ODk2ZA=="} style={linkStyle}>
        <button className="mt-5 mx-auto text-white px-10 py-3 rounded-lg bg-pink-500 hover:bg-pink-600 flex items-center">
          <FaInstagram className="mr-2" />
          Instagram
        </button>
      </Link>
      <Link to={"http://www.tiktok.com/@nutriinteligenteonline"} style={linkStyle}>
        <button className="mt-5 text-white px-10 py-3 rounded-lg bg-blue-500 hover:bg-blue-600 flex items-center">
          <FaTiktok className="mr-2" />
          Tiktok
        </button>
      </Link>

      <button className="mt-10 border mx-auto text-gray-800 px-10 py-3 rounded-lg hover:bg-gray-300" onClick={Voltar}>
        VOLTAR
      </button>
    </div>
  );
};

export default Suporte;