import React, { useEffect, useState, useRef } from 'react';
import { UserAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import jsPDF from 'jspdf';
import ErrorCard from '../Error/ErrorCard';
import ErrorCard3 from '../Error/ErrorCard3';
import ErrorCard2 from '../Error/ErrorCard2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';



function LocalTreino() {
  
    const [successMessageVisible, setSuccessMessageVisible] = useState(false)
    const [userTreino, setUserTreino] = useState(null);
    const [userTickets, setUserTickets] = useState(null);
    const [possuiTicket, setPossuiTicket] = useState(false);
    const [showError3, setShowError3] = useState(true);
    const [showErro2 , setShowError2] = useState(true)
    const [showError, setShowError] = useState(true);
    const edicaoDieta = true
    const [userTicketsUsados,setUserTicketsUsados] = useState(null)
    
   
    const { user, retornaTreino, retornaTicket, retornaTicketUsado, adicionarTreino,retirarEdicaoDieta,retornaEdicaoDieta } = UserAuth();
    const [edit, setEdit] = useState(false)
    const resultTextareaRef = useRef(null);
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate();
  
  

  
    const handleSave = () => {
      adicionarTreino(resultTextareaRef.current.value);
      return;
    };
  
  
    const copyAnswer = () => {
      if (userTreino) {
        navigator.clipboard.writeText(userTreino)
          .then(() => {
            setSuccessMessageVisible(true);
  
            // Ocultar o mini card após 2 segundos (opcional)
            setTimeout(() => {
              setSuccessMessageVisible(false);
            }, 3000);
          })
          .catch(err => {
            console.error('Erro ao copiar para a área de transferência:', err);
          });
      }
    };
  
    const closeError = () => {
      setShowError(false);
    }
  
  
    const closeError3 = () => {
      setShowError3(false);
      Voltar()
    }
  
    const closeError2 = () => {
      setShowError2(false);
      
    }
    const Voltar = () => {
      navigate('/montartreino');
    }
  
    
    useEffect(() => {
      // Simula um tempo de carregamento de 3 segundos
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000);
  
      // Limpa o timer quando o componente é desmontado
      return () => clearTimeout(timer);
    }, []);
  
    
  
   
    useEffect(() => {
      retornaTreino()
        .then(async (treino) => {
            console.log(treino)
          if (treino !== null) {
             setUserTreino(treino);
          } else {
            setUserTreino('');
          }
          
        })
        .catch((error) => {
          console.error('Erro ao obter os tickets:', error);
          setUserTreino('Erro ao obter os tickets');
        });
  
      retornaTicket()
        .then((tickets) => {
          if (tickets !== null) {
            setUserTickets(tickets);
            if (tickets > 0) {
              setPossuiTicket(true)
            }
  
          } else {
            setUserTickets("Nenhum ticket disponível");
          }
  
        })
        .catch((error) => {
          setUserTickets("Erro ao obter os tickets");
        });
  
      retornaTicketUsado()
        .then((tickets) => {
          if (tickets !== null) {
            setUserTicketsUsados(tickets);
  
          } else {
            console.log("Nenhum ticket disponível", userTicketsUsados);
          }
  
        })
        .catch((error) => {
          console.log("Erro ao obter os tickets");
        });
       
   
  
    }, [retornaTreino, retornaTicket, possuiTicket, userTickets,retornaTicketUsado, userTicketsUsados, retornaEdicaoDieta, userTreino]);
  
  
  
    
    const handleDownloadPDF = () => {
      if (!userTreino) {
        return;
      }
      const pdf = new jsPDF();
      const margin = 10;
      const pageWidth = pdf.internal.pageSize.width;
      const pageHeight = pdf.internal.pageSize.height - 1 * 8;
      const fontSize = 12;

  
      const splitText = pdf.splitTextToSize(`\nTreino Totalmente Personalizado e Exclusivo\n${userTreino}`, pageWidth - margin * 2);
  
      var headerHeight =  49;
      pdfimage(user.email)
     function pdfimage(email){
     switch(email) {
        default:
        return headerHeight = 10;
      }
    }
  
      let yPosition = margin + headerHeight - 8; 
  
      for (let i = 0; i < splitText.length; i++) {
        if (yPosition + 5 > pageHeight) {
          pdf.addPage();
          yPosition = margin;
        }
  
        pdf.setFontSize(fontSize);
  
        if (splitText[i].includes('Treino Totalmente Personalizado e Exclusivo')) {
          pdf.setFontSize(16)
        } else {
          pdf.setTextColor(0);
        }
  
        if (splitText[i].includes('Segunda') ||
          splitText[i].includes('Segunda-Feira') ||
          splitText[i].includes('Terça') ||
          splitText[i].includes('Terça-Feira') ||
          splitText[i].includes('Querta') ||
          splitText[i].includes('Quarta-Feira') ||
          splitText[i].includes('Quinta') ||
          splitText[i].includes('Sexta') ||
          splitText[i].includes('Sexta-Feira') ||
          splitText[i].includes('Sábado') ||
          splitText[i].includes('Domingo') ||
          splitText[i].includes('Treino Totalmente Personalizada e Exclusiva') ||
          splitText[i].includes('Quinta-Feira')) {
          user.email==='raphaelamoutinho1@gmail.com'? pdf.setTextColor(179, 34, 93) : pdf.setTextColor(50, 205, 50) ; // Cor azul 
        } else {
          pdf.setTextColor(0); // Volta à cor padrão do texto (preto)
        }
  
        pdf.text(margin, yPosition, splitText[i]);
  
        yPosition += fontSize - 2;
      }
  
  
      // Rodapé moderno
      user.email==='raphaelamoutinho1@gmail.com'?pdf.setFillColor(179, 34, 93):pdf.setFillColor('#1C9E22'); // Cor de fundo verde
      pdf.rect(0, pageHeight - 10, pageWidth, 30, 'F');
      pdf.setFontSize(12); // Ajusta o tamanho da fonte
      pdf.setTextColor('#ffffff'); // Texto branco
  
      // Centraliza o texto no rodapé
      let footerText = user.email==='raphaelamoutinho1@gmail.com'?
      `Caso tenha alguma dúvida, entre em contato.
      ` : 'Caso tenha alguma dúvida, entre em contato.';
      let textWidth = pdf.getTextWidth(footerText);
      pdf.text((pageWidth - textWidth) / 2, pageHeight , footerText);
  
      const fileName = 'Treino.pdf';
      pdf.save(fileName);
  };
  
    function shouldApplyBold(line) {
      const palavrasChave = ['Segunda' || 'Segunda-feira', 'Terça' || 'Terça-feira', 'Quarta' || 'Quarta-Feira', 'Quinta', 'Sexta', 'Sábado' || 'Sabado', 'Domingo'];
      return palavrasChave.some(palavra => line.includes(palavra));
    }
    const dietaDividida = userTreino ? userTreino.split(/[\n]+/) : [];
    const cafeDaManhaIndex = dietaDividida.findIndex(line =>
      line.includes('Segunda-Feira') || line.includes('Segunda') 
    );
  
    const lancheDaManhaIndex = dietaDividida.findIndex(line =>
      line.includes('Terça') 
    );
  
    const almocoIndex = dietaDividida.findIndex(line =>
      line.includes('Quarta') 
    );
  
    const lancheDaTardeIndex = dietaDividida.findIndex(line =>
      line.includes('Quinta') 
    );
  
    const jantaIndex = dietaDividida.findIndex(line =>
      line.includes('Sexta') 
    );
  
    const treinoIndex = dietaDividida.findIndex(line =>
      line.includes('Sábado') || line.includes('Sabado')
    );
    const treinoIndex2 = dietaDividida.findIndex(line =>
        line.includes('Domingo') 
      );

      console.log(treinoIndex2)
  
    const inicio = dietaDividida.slice(0, cafeDaManhaIndex)
    const cafeDaManha = dietaDividida.slice(cafeDaManhaIndex, lancheDaManhaIndex ===-1 ? almocoIndex: lancheDaManhaIndex );
    const lancheDaManha = dietaDividida.slice(lancheDaManhaIndex, almocoIndex);
    const almoco = dietaDividida.slice(almocoIndex, lancheDaTardeIndex  ===-1 ? jantaIndex: lancheDaTardeIndex );
    const lancheDaTarde = dietaDividida.slice(lancheDaTardeIndex, jantaIndex);
    const janta = dietaDividida.slice(jantaIndex, treinoIndex ===-1  ? treinoIndex2: treinoIndex  );
    const treino = dietaDividida.slice(treinoIndex, treinoIndex2 ? treinoIndex2 : "");
    const treino2 = dietaDividida.slice(treinoIndex2 ? treinoIndex2 : '' );
    const [dietaEditavel, setDietaEditavel] = useState(dietaDividida);
    
      const handleChange = (event) => {
        const newValue = event.target.value.split('\n');
        setDietaEditavel(newValue);
    };
  
    return (
      <div className='flex flex-col items-center p-4 md:w-4/5 mx-auto'>
  
         {loading ? (
        <div className="flex justify-center items-center h-screen">
        <FontAwesomeIcon icon={faSpinner} color='#17A34A' spin size="3x" />
      </div>
      ) : (
        <div className='w-full'>
     
          <div className='bg-white p-4 rounded-2xl shadow-lg lg:w-3/5 lg:mx-auto'>
          <div className='flex justify-between items-center mb-2'>
          
             
      </div>
  
          <div className='my-1 flex justify-between mb-2'>
            <button className='bg-red-500 hover:bg-red-700 text-white font-bold  w-[110px] h-[60px] rounded mx-auto' onClick={handleDownloadPDF}
            >
              Baixar 📥
            </button>
  
            <button className='bg-green-500 hover:bg-green-700 text-white font-bold w-[110px] h-[60px] rounded mx-auto' onClick={copyAnswer}
              >
              Copiar 📋
            </button>
          </div>
     
        <div className='flex flex-column md:w-4/5 mx-auto'>
        {showError3 && !userTreino && possuiTicket && (
          <ErrorCard2
            message="Você tem saldo disponiveis. Clique em 'Usar Tickets' abaixo para preencher suas informações e gerar seu Treino"
            onClose={closeError3} // Fechar o card de erro quando o botão "OK" é clicado
          />
        )}
  
        {showErro2 && userTreino && (
          <ErrorCard3
            message="Acesse o site pelo navegador Safari ou Chrome para baixar o PDF do seu treino"
            onClose={closeError2} // Fechar o card de erro quando o botão "OK" é clicado
          />
        )}
  
        {showError && !userTreino && !possuiTicket && (
          <ErrorCard
            id="error1" // Defina um ID único para este card  
            message="Você ainda não tem saldo para gerar seu treino. Por favor, realize o pagamento para ter a seu treino gerado. Caso já tenha pago, entre em contato."
            onClose={closeError} // Use a função closeError para fechar este card
          />
        )}
      
        </div>
        </div>
        
  
        {successMessageVisible && (
    <div className="chat-error text-center bg-red-600 text-white py-3 px-4 mx-auto w-[90%] mt-4 rounded-lg shadow-lg">
      Dieta copiada com sucesso 🎯 Agora só colar 📄
    </div>
  )}
  
  
  
        {userTreino && (<div>
          {edit === false ? ( 
          <div>
          {inicio.map((line) => line).length !== 0 && <div className='bg-green-500 font-bold text-xl text-center pb-2 mt-4 shadow-xl rounded-xl'>
            {inicio.map((line, index) => (
              <p className='p-1 m-0' key={index} style={{ color: 'white' }}>{line}</p>
            ))}
          </div>
          }
          <div className='bg-green-500 mt-4 py-2 shadow-xl rounded-xl'>
            {cafeDaManha.map((line, index) => (
              <p className='p-2 m-0' key={index} style={{ color: 'white' }}>
                {shouldApplyBold(line) ? (
                  <strong className='my-2'>{line}</strong>
                ) : (
                  line
                )}
              </p>
            ))}
          </div>
          {lancheDaManha.length !== 0  && (
          <div className='bg-green-600 mt-5 py-2 shadow-xl rounded-xl' >
            {lancheDaManha.map((line, index) => (
              <p className=' p-2 m-0' key={index} style={{ color: 'white' }}>
                {shouldApplyBold(line) ? (
                  <strong className='my-2'>{line}</strong>
                ) : (
                  line
                )}
              </p>
            ))}
          </div>
          )}
          <div className='bg-green-700 mt-5 py-2 shadow-xl rounded-xl' >
            {almoco.map((line, index) => (
              <p className='p-2 m-0' key={index} style={{ color: 'white' }}>
                {shouldApplyBold(line) ? (
                  <strong className='my-2'>{line}</strong>
                ) : (
                  line
                )}
              </p>
            ))}
          </div>
          {lancheDaTarde.length !== 0  && (
          <div className='bg-green-800 mt-5 py-2 shadow-xl rounded-xl'>
            {lancheDaTarde.map((line, index) => (
              <p className='p-2 m-0 rounde' key={index} style={{ color: 'white' }}>
                {shouldApplyBold(line) ? (
                  <strong className='my-2'>{line}</strong>
                ) : (
                  line
                )}
              </p>
            ))}
          </div>
          )}
          <div className='bg-green-900 mt-5 py-2 shadow-xl rounded-xl'>
            {janta.map((line, index) => (
              <p className='p-2 m-0' key={index} style={{ color: 'white' }}>
                {shouldApplyBold(line) ? (
                  <strong className='my-2'>{line}</strong>
                ) : (
                  line
                )}
              </p>
            ))}
          </div>
          
          {treino.length !== 0 && ( 
          <div className='bg-gray-900 mt-5 py-2 shadow-xl rounded-xl'>
            {treino.map((line, index) => (
              <p className='p-2 m-0' key={index} style={{ color: 'white' }}>
                {shouldApplyBold(line) ? (
                  <strong className='my-2'>{line}</strong>
                ) : (
                  line
                )}
              </p>
            ))}
            
          </div>
          )}
          {treino2.length !== 0  && (
          <div className='bg-gray-800 mt-5 py-2 shadow-xl rounded-xl'>
            {treino2.map((line, index) => (
              <p className='p-2 m-0' key={index} style={{ color: 'white' }}>
                {shouldApplyBold(line) ? (
                  <strong className='my-2'>{line}</strong>
                ) : (
                  line
                )}
              </p>
            ))}
            
          </div>
          )}
        </div> ) : 
        (
          <div className='flex flex-col items-center'>
            <h1>Modo Edição de Dieta</h1>
            <textarea
          className='bg-white w-full text-black p-4 shadow-xl rounded-xl'
          style={{ lineHeight: '2.5' }}
              ref={resultTextareaRef}
                  rows={dietaEditavel.length+5}
                  value={dietaEditavel.join('\n')}
                  onChange={handleChange}
                  
              >
              </textarea>
          </div>
      )}
        
  
    <div className='flex flex-col items-center justify-around rounded-2xl p-3 shadow-lg bg-white mt-3 lg:w-1/2 lg:mx-auto'>   

    
      <div className='flex justify-around w-full'>
      {edit === false && edicaoDieta === true && (
        <button
          className="mt-2 hover:bg-blue-600 text-white mx-auto px-6 py-3 rounded-lg bg-blue-600 transition duration-300 ease-in-out"
          onClick={() => {
            setEdit(!edit)
            setDietaEditavel(dietaDividida)
            window.scrollTo(0, 0)
          }}
        >
          Editar 
        </button>
        )}
      <button
          className="mt-2 hover:bg-blue-600 text-white mx-auto px-6 py-3 rounded-lg bg-blue-600 transition duration-300 ease-in-out"
          onClick={() => {
            navigate('/home')
          }}
        >
          Inicio 
        </button>
      </div>
       
    </div>
          {edit === true && (
            <div>
        <div className='flex items-center justify-around'>
        <button
          className="mt-4 hover:bg-red-600 text-white mx-auto px-6 py-3 rounded-lg bg-red-600 transition duration-300 ease-in-out"
          onClick={() => {
            handleSave()
            setEdit(!edit)
            retirarEdicaoDieta()
            window.location.reload()
           }}
        >
          Salvar 
        </button>
  
  <button
  className="mt-4 hover:bg-red-600 text-white mx-auto px-6 py-3 rounded-lg bg-red-600 transition duration-300 ease-in-out"
  onClick={() => {setEdit(!edit)
    setDietaEditavel(dietaDividida)}}
  >
  Cancelar 
  </button>
  </div> 
  
  </div>
          )}
        </div> 
        )}
      </div>
  
  )}
  </div>)
}

export default LocalTreino;