import axios from "axios";
import "../ChatContainer/ChatContainer.css";
import { UserAuth } from "../../context/AuthContext";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";

function PageTreino() {
    const {user, adicionarTreino, retornaTicket, retirarTicket, adicionarTicketUsado } = UserAuth()
    const resultTextareaRef = useRef(null);
    const navigate = useNavigate()
    const [preferencia, setPreferencia] = useState("");
    const [dias, setDias] = useState("");
    const [nivel, setNivel] = useState("");
    const [quantidade, setQuantidade] = useState("")
    const [goal, setGoal] = useState("");
    const [answer, setAnswer] = useState("")
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [genero, setGeneroSelecionado] = useState('');
    const [selectedMusculos, setSelectedMusculos] = useState([]);
    const [error, setError] = useState("");
    const [recomend, setRecomend] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [userTickets, setUserTickets] = useState(null);
    const availableMusculos = [
        {  treino: "Peito" },
        { treino: "Triceps" },
        {  treino: "Costas" },
        {  treino: "Biceps" },
        {  treino: "Abdomem" },
        {  treino: "Quadriceps" },
        { treino: "Pernas" },
        {  treino: "Gluteos" },
        {  treino: "Ombro" },
      ];

      useEffect(() => {
        retornaTicket()
          .then((tickets) => {
            if (tickets !== null) {
              setUserTickets(tickets);
    
            } else {
              setUserTickets("Nenhum ticket disponível");
            }
          })
          .catch((error) => {
            setUserTickets("Erro ao obter os tickets");
          });
    
    
      }, [retornaTicket, userTickets]);

      const goToSuporte = () => {
        navigate('/suporte');
      };

      const handleSave = () => {
        adicionarTreino(resultTextareaRef.current.value);
        navigate('/treino')
        return;
      };

      const selecionarGenero = (genero) => {
        setGeneroSelecionado(genero);
      };

      function handleMusculosSelection(
        selectedMusculos,
        setSelectedMusculos,
        treino,
      ) {
        if (selectedMusculos.some((item) => item.treino === treino)) {
          setSelectedMusculos(selectedMusculos.filter((item) => item.treino !== treino));
        } else if (selectedMusculos.length < 6) {
          setSelectedMusculos([...selectedMusculos, { treino }]);
        }
      }
    
    const sendRequest = async (data) => {
        try {
          const result = await axios.post(`${process.env.REACT_APP_API_URL}/GerarTreino`, { data: data });
          setAnswer(result.data.answer);
        } catch (error) {
          console.error('Erro ao enviar a requisição:', error);
        }
      };

      const handleSubmit = async () => {
        const data = `
        Faça um TREINO personalizado para ${dias} com ênfase em ${goal} informando ${quantidade} O meu género é ${genero}, o meu nível é ${nivel}, e o treino deve ser no local: ${preferencia}. Além disso, gostaria de incluir exercícios específicos para fortalecer os meus músculos favoritos, que são ${selectedMusculos}. Não inclua informações adicionais e nem observações APENAS o TREINO"  `;
        ;
        try{
    setIsLoading(true);
    document.body.classList.add("scroll-lock");
     
        const interval = setInterval(() => {
          setLoadingProgress(prevProgress => {
            if (prevProgress >= 99) {
              clearInterval(interval);
              return prevProgress; // Mantém o progresso em 90% até a função terminar
            }
            return prevProgress + 1; // Incrementa 10% a cada intervalo
          });
        }, 700);
        await sendRequest(data);
        setLoadingProgress(100); // Completa a barra de progresso
        clearInterval(interval);
      
      
    setPreferencia("");
    setDias("");
    setNivel("");
    setQuantidade("");
    setGoal("")
    setSelectedMusculos([])

      setError("");

      setRecomend(true);





    } catch (err) {
      console.error(err);
      setError('Estamos com bastante requisições! Coloque suas informações novamete para gerar sua dieta');
    } finally {
      setIsLoading(false);
      retirarTicket()
      adicionarTicketUsado()
      // Desativar o bloqueio de rolagem da página
      document.body.classList.remove('scroll-lock'); // Remova a classe
    }
  };



    return (  <div className=" text-center ">
     <div className="chat-conteiner pt-4 mb-0 mx-auto border">
      <div className="chat-inputs mx-auto">
        <div className="chat-header">
        <h2>Treino Personalizado </h2>
        <p className="text-muted text-center text-xs mt-3">
          Preencha as informações do paciente abaixo.
        </p>
        </div>
      
        <div className="select-wrapper">
          <select
            id="goal"
            value={goal}
            onChange={(e) => setGoal(e.target.value)}
          >
            <option value="">Objetivo Atual </option>
            <option value="Emagrecer">Emagrecer</option>
            <option value="Hipertrofia">Hipertrofia</option>
            <option value="Aumentar Força">Aumentar Força</option>
            <option value="Aumentar Resistencia">Aumentar Resistência</option>
            <option value="Queima de Gordura">Queima de Gordura</option>
          </select>
        </div>
        <div className="select-wrapper">
          <select
            id="preferencia"
            value={preferencia}
            onChange={(e) => setPreferencia(e.target.value)}
          >
            <option value="">Preferência de Treino</option>
            <option value="Casa">Casa</option>
            <option value="Academia">Academia</option>
          </select>
        </div>
        <div className="select-wrapper">
          <select
            id="nivel"
            value={nivel}
            onChange={(e) => setNivel(e.target.value)}
          >
            <option value="">Nivel de Treino</option>
            <option value="Iniciante">Iniciante</option>
            <option value="Intermediario">Intermediário</option>
            <option value="Avançado">Avançado</option>

          </select>
        </div>
        <div className="select-wrapper">
          <select
            id="Dias"
            value={dias}
            onChange={(e) => setDias(e.target.value)}
          >
            <option value="">Dias de Treino</option>
            <option value="Segunda a Sexta">Segunda a Sexta</option>
            <option value="Segunda a Domingo">Segunda a Domingo</option>
            <option value="Segunda, Quarta e Sexta">Segunda, Quarta e Sexta</option>
            <option value="Segunda, Quarta, Sexta e Sábado">Segunda, Quarta, Sexta e Sábado</option>
          </select>
        </div>
        <div className="select-wrapper">
          <select
            id="quantidade"
            value={quantidade}
            onChange={(e) => setQuantidade(e.target.value)}
          >
            <option value="">Quantidade de Exercicios🎯</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
          </select>
        </div>
        <div className="select-wrapper">
          <br></br>
          <div className=" w-full flex justify-evenly">
            <button
              className={`button-inputM py-3 px-4 md:px-5 ${genero === 'Masculino' ? 'selected' : ''}`}
              onClick={() => selecionarGenero('Masculino')}
            >
              Masculino
            </button>
            <button
              className={`button-inputF py-3 px-4 md:px-5 ${genero === 'Feminino' ? 'selectedF' : ''}`}
              onClick={() => selecionarGenero('Feminino')}
            >
              Feminino
            </button></div>
        </div>
      </div>

      <h2 style={{ backgroundColor: '#17A34A', color: '#FFFFFF' }} className="mx-auto p-3 rounded-full inline-block text-lg">
        Prioridades Musculares
      </h2>
      <p className="p-0 m-0 mt-3 text-[#696767] text-center text-xs">Clique nos músculos para selecionar</p>
      <div className="selection-food flex flex-col border font-bold rounded-lg mb-2 p-2">
        <label htmlFor="obs" className="text-color">Prioridade Musculares</label>
        <div className="food-container">
          {availableMusculos.map((treino, index) => (
            <label key={index} className={`food-item flex w-[100%] justify-center items-center text-sm md:text-lg ${selectedMusculos.some((item) => item.treino === treino.treino) ? 'selectedfood ' : ''}`}>
              <input
                className="hidden"
                type="checkbox"
                value={treino.treino}
                checked={selectedMusculos.some((item) => item.treino === treino.treino)}
                onChange={() =>
                    handleMusculosSelection(
                    selectedMusculos,
                    setSelectedMusculos,
                    treino.treino
                  )
                }
                disabled={
                  selectedMusculos.length === 6 &&
                  !selectedMusculos.some((item) => item.treino === treino.treino)
                }

              />
              <span className="checkmark">
                {treino.treino}
              </span>
            </label>
          ))}
        </div>
      </div>
      {user.email === 0 ? (

<div className="rounded-lg overflow-hidden bg-gradient-to-r from-green-600 to-green-700 p-6 shadow-xl">
  <div className="mb-4">
    <span className="inline-block bg-green-50 text-green-800 font-semibold p-2 rounded-lg text-lg shadow">
      ENTRE EM CONTATO
    </span>
    <p className="text-white mt-4">
      Para Recarregar sua Conta.
    </p>
  </div>
  <button
    className="w-full bg-green-50 hover:bg-green-800 text-green-800 hover:text-green-50 py-3 px-4 rounded-md shadow-md transition-colors duration-300 ease-in-out font-bold"
    onClick={goToSuporte}>
    Entrar em Contato
  </button>
</div>


) : (
<div>
  <button
className={`py-3 px-4 my-3 rounded-md shadow-md transition-colors duration-300 ease-in-out font-bold ${
userTickets === '0' 
? 'bg-gray-400 cursor-not-allowed'
: 'bg-gradient-to-r from-green-600 to-green-700 text-white hover:from-green-700 hover:to-green-800'
}`}
disabled={userTickets === '0' }
onClick={handleSubmit}>
Montar Treino Personalizado
</button>

  {error && (
    <div className="chat-error text-white bg-red-600 rounded-md mb-3">
      {error}
    </div>
  )}
</div>
)}
<br></br>
{recomend && (<div className="chat-response">
<label htmlFor="response" className=" font-bold text-xl w-[90%] text-center">Dieta Personalizada</label>
<textarea
  id="response"
  ref={resultTextareaRef}
  name="response"
  rows="20"
  value={answer}
  readOnly
>
</textarea>
</div>)}
<div className="chat-response">
{recomend && (
  <div style={{
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000
  }}>
    <div style={{
      backgroundColor: 'white',
      borderRadius: '10px',
      padding: '20px',
      maxWidth: '500px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      border: '1px solid #ddd',
      position: 'relative'
    }}>
      <div style={{
        position: 'absolute',
        top: '-25px',
        right: '20px',
        backgroundColor: '#FF4D61',
        color: 'white',
        borderRadius: '50%',
        width: '50px',
        height: '50px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '24px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)'
      }}>
        ⚠️ {/* Ícone de aviso */}
      </div>
      <h3 style={{
        textAlign: 'center',
        color: '#333',
        fontWeight: 'bold',
        marginBottom: '15px'
      }}>
        Treino Gerado com Sucesso
      </h3>
      <p style={{
        textAlign: 'center',
        color: '#555',
        marginBottom: '20px'
      }}>
        Clique no botão abaixo para salvar sua dieta e visualizá-la. Depois você poderá baixar em PDF.
      </p>
      <div style={{ textAlign: 'center' }}>
        <button onClick={handleSave} style={{
          backgroundColor: '#FF4D61',
          color: 'white',
          padding: '10px 20px',
          borderRadius: '5px',
          border: 'none',
          cursor: 'pointer'
        }}>
          SALVAR TREINO
        </button>
      </div>
    </div>
  </div>
)}

        </div>
        {isLoading && (
        <div className="overlay">
          <div className="Spinner">

            <div className="ComidaEmoji">🏋🏻‍♀️</div>

            <TailSpin
              color="#00dc25"
              height={80}
              width={80}
            />
            <p>Carregando ⏳...{loadingProgress}%</p>
            <p className="AvisoNaoSaia">Por favor, não saia da página enquanto geramos seu Treino 💪.</p>
          </div>
        </div>
      )}
      </div>
    

      </div>
        )
}

export default PageTreino;