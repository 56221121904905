import { createContext, useContext, useEffect, useState } from 'react';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  sendPasswordResetEmail,
  deleteUser,
} from 'firebase/auth';
import { auth } from '../firebase';
import { get, getDatabase, ref, set, update} from 'firebase/database';
import { useLocation, useNavigate } from 'react-router-dom';

import { saveUserInfo,getPaymentId, removeUserInfo, removePaymentId, removePaymentStartTime } from '../helpers/localStorage.helper';


const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [theme, setTheme] = useState('light');
  const [user, setUser] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  
 

  const createUser = async (email, password) => {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);

    const uid = userCredential.user.uid;
    // Crie um nó no Realtime Database para armazenar informações do usuário
    const db = getDatabase();
    const userRef = ref(db, "users/" + uid);
    set(userRef, {
      email: email,
      Dieta: "",
      Tickets: 0,
      lastLogin: new Date().toISOString()
    });
    setUser(userCredential.user);
    saveUserInfo(userCredential.user);
  };

  const retornaDados = async () => {
    try {
      const current = auth.currentUser;
      if (current != null) {
        const db = getDatabase();
        const userRef = ref(db, `users/${current.uid}`);
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          const userData = snapshot.val();
          const userPeso = userData.Peso;
          const userAltura = userData.Altura;
          const userNome = userData.Nome;
          // const userTMB = userData.TMB;
          const userIMC = userData.IMC;
          return [userPeso, userAltura, userIMC, userNome]; // Retorna o valor dos tickets
        } else {
          return null; // Retorna null se o usuário não existir
        }
      } else {
        return null; // Retorna null se não houver usuário autenticado
      }
    } catch (error) {
      console.error("Erro ao recuperar tickets:", error);
      return null; // Retorna null em caso de erro
    }
  };

  const salvarDados = async (nome, peso, altura, imc) => {
    try {
      const current = auth.currentUser;
      if (current) {
        const db = getDatabase();
        const userRef = ref(db, `users/${current.uid}`);

        const snapshot = await get(userRef);

        if (snapshot.exists()) {
          const setPeso = peso;
          const setAltura = altura;
          const setNome = nome;
          const setImc = imc;

          update(userRef, {
            email: current.email,
            Nome: setNome,
            Peso: setPeso,
            Altura: setAltura,
            // TMB: setTMB,
            IMC: setImc
          })

        }
      }
    } catch (error) {
      console.error("Erro dieta", error);
    }
  };

  const deleteAccount = async () =>{
    try{
      const current = auth.currentUser;
      deleteUser(current).then(() => {
        navigate("/")
      }).catch((error) => {
      });

      }catch{
    }
  }

  const redefinePassword = async (email) =>{
    try{
    await sendPasswordResetEmail(auth, email)
      .then(() => {
      })
      .catch((error) => {
      });
    }catch{
    }
  }

  const signIn = async (email, password) => {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);

    const uid = userCredential.user.uid;
    // Crie um nó no Realtime Database para armazenar informações do usuário
    const db = getDatabase();
    const userRef = ref(db, "users/" + uid);

    update(userRef, {
      email: email,
      lastLogin: new Date().toISOString()
    });
    setUser(userCredential.user);
    saveUserInfo(userCredential.user);
  };

  const retornaTicket = async () => {
    try {
      const current = auth.currentUser;
      if (current != null) {
        const db = getDatabase();
        const userRef = ref(db, `users/${current.uid}`);
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          const userData = snapshot.val();
          const userTickets = userData.Tickets;
          
          return userTickets; // Retorna o valor dos tickets
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {
      console.error("Erro ao recuperar tickets:", error);
      return null;
    }
  };

  const retornaTicketUsado = async () => {
    try {
      const current = auth.currentUser;
      if (current != null) {
        const db = getDatabase();
        const userRef = ref(db, `users/${current.uid}`);
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          const userData = snapshot.val();
          const userTickets = userData.TicketsUsados;
          return userTickets; // Retorna o valor dos tickets
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {
      console.error("Erro ao recuperar tickets:", error);
      return null;
    }
  };

  const adicionarTicket = async (tipo) => {
    try {
      const current = auth.currentUser;
      if (current) {
        const db = getDatabase();
        const userRef = ref(db, `users/${current.uid}`);

        const snapshot = await get(userRef);

        if (snapshot.exists()) {

          const Tickets = snapshot.val().Tickets
          // eslint-disable-next-line
          if (Tickets == 0) {
            const setTicket = tipo
            update(userRef, {
              email: current.email,
              Tickets: setTicket,
              lastLogin: new Date().toISOString()
            })
            
          } else {
          }
        }
      }
    } catch (error) {
      console.error("Erro ao recuperar tickets:", error);
    }
  };

  const adicionarDieta = async (dietaSalva) => {
    try {
      const current = auth.currentUser;
      if (current) {
        const db = getDatabase();
        const userRef = ref(db, `users/${current.uid}`);

        const snapshot = await get(userRef);

        if (snapshot.exists()) {


          // eslint-disable-next-line

          const setDieta = dietaSalva
          update(userRef, {
            email: current.email,
            Dieta: setDieta,
            lastLogin: new Date().toISOString()
          })

        }
      }
    } catch (error) {
      console.error("Erro dieta", error);
    }
  };

  const adicionarTreino = async (treinoSalvo) => {
    try {
      const current = auth.currentUser;
      if (current) {
        const db = getDatabase();
        const userRef = ref(db, `users/${current.uid}`);

        const snapshot = await get(userRef);

        if (snapshot.exists()) {


          // eslint-disable-next-line

          const setTreino = treinoSalvo
          update(userRef, {
            email: current.email,
            Treino: setTreino,
            lastLogin: new Date().toISOString()
          })

        }
      }
    } catch (error) {
      console.error("Erro dieta", error);
    }
  }

  const retornaDieta = async () => {
    try {
      const current = auth.currentUser;
      if (current != null) {
        const db = getDatabase();
        const userRef = ref(db, `users/${current.uid}`);
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          const userData = snapshot.val();
          const userDieta = userData.Dieta;
          return userDieta; // Retorna o valor dos tickets
        } else {
          return null; // Retorna null se o usuário não existir
        }
      } else {
        return null; // Retorna null se não houver usuário autenticado
      }
    } catch (error) {
      console.error("Erro ao recuperar Dieta:", error);
      return null; // Retorna null em caso de erro
    }
  };
  const retornaTreino = async () => {
    try {
      const current = auth.currentUser;
      if (current != null) {
        const db = getDatabase();
        const userRef = ref(db, `users/${current.uid}`);
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          const userData = snapshot.val();
          console.log(userData)
          const userTreino = userData.Treino;
          return userTreino; // Retorna o valor dos tickets
        } else {
          return null; // Retorna null se o usuário não existir
        }
      } else {
        return null; // Retorna null se não houver usuário autenticado
      }
    } catch (error) {
      console.error("Erro ao recuperar Treino:", error);
      return null; // Retorna null em caso de erro
    }
  };



  const retirarTicket = async () => {

    try {
      const current = auth.currentUser;
      if (current) {
        const db = getDatabase();
        const userRef = ref(db, `users/${current.uid}`);

        const snapshot = await get(userRef);

        if (snapshot.exists()) {

          const Tickets = snapshot.val().Tickets
          // eslint-disable-next-line
          if (Tickets > 0) {
            const setTicket = Tickets - 1
            update(userRef, {
              email: current.email,
              Tickets: setTicket,
              lastLogin: new Date().toISOString()
            })
            navigate(location.pathname);
          } else {
          }
        }
      }
    } catch (error) {
    }
  };
  const adicionarTicketUsado = async () => {

    try {
      const current = auth.currentUser;
      if (current) {
        const db = getDatabase();
        const userRef = ref(db, `users/${current.uid}`);

        const snapshot = await get(userRef);

        if (snapshot.exists()) {

          if(snapshot.val().TicketsUsados){
           const Tickets = snapshot.val().TicketsUsados + 1
           update(userRef, {
            email: current.email,
            TicketsUsados: Tickets,
            lastLogin: new Date().toISOString()
          })
          // eslint-disable-next-line
        }else{
         const Tickets = 1
         update(userRef, {
          email: current.email,
          TicketsUsados: Tickets,
          lastLogin: new Date().toISOString()
        })
        }
      }
    }
    } catch (error) {
    }
  };

  const adicionarEdicaoDieta = async () => {

    try {
      const current = auth.currentUser;
      if (current) {
        const db = getDatabase();
        const userRef = ref(db, `users/${current.uid}`);

        const snapshot = await get(userRef);

        if (snapshot.exists()) {

          if(snapshot.val().edicaoDieta){
           update(userRef, {
            email: current.email,
            edicaoDieta: true,
            lastLogin: new Date().toISOString()
          })
          // eslint-disable-next-line
        }else{
         update(userRef, {
          email: current.email,
          edicaoDieta: true,
          lastLogin: new Date().toISOString()
        })
        }
      }
    }
    } catch (error) {
    }
  };

  const retirarEdicaoDieta = async () => {

    try {
      const current = auth.currentUser;
      if (current) {
        const db = getDatabase();
        const userRef = ref(db, `users/${current.uid}`);

        const snapshot = await get(userRef);

        if (snapshot.exists()) {

          if(snapshot.val().edicaoDieta){
           update(userRef, {
            email: current.email,
            edicaoDieta: false,
            lastLogin: new Date().toISOString()
          })
          // eslint-disable-next-line
        }else{
         
         update(userRef, {
          email: current.email,
          edicaoDieta: false,
          lastLogin: new Date().toISOString()
        })
        }
      }
    }
    } catch (error) {
    }
  };

  const retornaEdicaoDieta = async () => {
    try {
      const current = auth.currentUser;
      if (current != null) {
        const db = getDatabase();
        const userRef = ref(db, `users/${current.uid}`);
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          const userData = snapshot.val();
          const userTickets = userData.edicaoDieta;
          return userTickets; // Retorna o valor dos tickets
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {
      console.error("Erro ao recuperar tickets:", error);
      return null;
    }
  };



  const logout = () => {
    
    const paypay = getPaymentId()
    if (paypay){
  
    removePaymentStartTime();
    removePaymentId();
    }
    removeUserInfo();
    return signOut(auth);
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      saveUserInfo(currentUser)
      if (currentUser !== null && window.location.pathname === "/") {
        navigate('/home');
      }

    });
    return () => {
      unsubscribe();
    };
  }, [navigate]);

  return (
    <UserContext.Provider value={{ createUser, user,logout,retirarEdicaoDieta,retornaTreino, adicionarTreino,signIn,retornaEdicaoDieta, adicionarEdicaoDieta ,retornaTicketUsado, salvarDados,redefinePassword,adicionarTicketUsado, deleteAccount, retornaDados, retornaTicket, adicionarTicket, retirarTicket, adicionarDieta, retornaDieta, theme, setTheme }}>
      {children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};
