import React from 'react';
import { jsPDF } from 'jspdf';
import receitafit from '../../../img/PizzaFit.png';

export default function ReceitasPdf() {
    const downloadPdf = () => {
        const doc = new jsPDF();
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();
        const headerHeight = 60; // Altura da imagem no cabeçalho
        const lineHeight = 10; // Altura aproximada de uma linha de texto

        // Adicionar imagem no cabeçalho
        doc.addImage(receitafit, 'PNG', 0, 0, pageWidth, headerHeight);


        const receitasFit = `
    Pizza Fit:

    1. Pizza de Frango com Aveia
    Ingredientes:
    - 1 xícara de farinha de aveia
    - 1 ovo
    - 1 colher de sopa de azeite
    - 1 colher de chá de fermento em pó
    - 1 pitada de sal
    - 1 peito de frango cozido e desfiado
    - 1/2 xícara de molho de tomate
    - 1 xícara de queijo muçarela ralado
    - 1 tomate fatiado
    - Orégano a gosto

    Modo de preparo:
    Misture a farinha de aveia, o ovo, o azeite, o fermento e o sal até formar uma massa homogênea. 
    Abra a massa em uma forma para pizza. Asse em forno pré-aquecido a 180°C por cerca de 10 minutos. 
    Retire do forno, espalhe o molho de tomate, distribua o frango desfiado, o queijo, as fatias de tomate e orégano. 
    Leve ao forno novamente por mais 10 minutos ou até o queijo derreter.

    2. Pizza de Couve-Flor
    Ingredientes:
    - 1 cabeça de couve-flor
    - 1 ovo
    - 1/2 xícara de queijo parmesão ralado
    - Sal e pimenta a gosto
    - Molho de tomate
    - Coberturas de sua preferência (vegetais, frango, atum, etc.)

    Modo de preparo:
    Triture a couve-flor em um processador até obter uma textura de arroz. 
    Cozinhe no micro-ondas por cerca de 8 minutos. Deixe esfriar e esprema para retirar o excesso de água. 
    Misture a couve-flor com o ovo, queijo parmesão, sal e pimenta. 
    Espalhe a mistura em uma forma para pizza, formando uma camada fina. 
    Asse em forno pré-aquecido a 200°C por cerca de 20 minutos. 
    Retire do forno, adicione o molho de tomate e as coberturas de sua escolha. Asse por mais 10 minutos.


    Tapioca + Suco Nutritivo:

    Tapioca de Chia com Ovo Cozido

    Tempo de preparo: 23 minutos (inclui o tempo de cozimento do ovo)
    Característica: Prática e fácil
    Rendimento: 1 porção
    Custo: R$3,75
    Ingredientes:

    3 colheres de sopa de goma de tapioca hidratada
    1 colher de sopa de chia
    1 ovo cozido amassado
    Modo de preparo:

    Peneire a tapioca e misture com a chia.
    Leve ao fogo até que a massa comece a se desprender da frigideira.
    Adicione o ovo amassado.
    Dobre a tapioca ao meio.
    Suco Verde de Abacaxi, Couve e Maçã

    Tempo de preparo: 6 minutos (não inclui o tempo de higienização)
    Característica: Rápido e fácil
    Rendimento: 5 copos
    Ingredientes:

    4 fatias de abacaxi
    3 folhas de couve
    4 folhas de hortelã
    1 maçã grande com casca
    400 mL de água ou água de coco
    Modo de preparo:

    Higienize a couve, a hortelã e a maçã.
    Descasque o abacaxi e corte em fatias.
    Coloque todos os ingredientes no liquidificador.
    Adicione a água aos poucos, conforme necessário.

    Hambúrguer

    Mandioca: 2 xícaras (colheres de sopa)
    Cebolinha: ½ xícara (colheres de chá)
    Manjericão seco: ½ xícara (colheres de chá)
    Quinoa em grão: 100g
    Azeite: 3 colheres de sopa
    Sal e pimenta-do-reino a gosto
    
    PREPARO DE HAMBÚRGUER

    Em uma panela, coloque água e quinoa. Cozinhe e lave a quinoa.
    Quando a quinoa estiver cozida, acrescente as ervas e o azeite.
    Molde os hambúrgueres.
    Em uma frigideira, coloque a farinha e doure os hambúrgueres até ficarem bem misturados.

    Sorvete de banana proteico

    Modo de Preparo 

    2 bananas

    1 scoop de whey

    Corte as bananas em rodelas e congele.

    Coloque as bananas congeladas em um processador até formar uma massa cremosa.

    Adicione o whey e misture bem.

    Está pronto em pouco tempo.




    
    
    `;

        const splitText = doc.splitTextToSize(receitasFit, 180);
        let yPos = headerHeight + 10; // Posição inicial do texto abaixo da imagem

        // Adicionar texto e criar novas páginas se necessário
        splitText.forEach((line) => {
            if (yPos + lineHeight > pageHeight) {
                doc.addPage();
                yPos = 10; // Resetar posição do texto para o topo da nova página
            }
            doc.text(line, 10, yPos);
            yPos += lineHeight;
        });

        // Salvar PDF
        doc.save("receitas-fit-pizza.pdf");
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px', marginTop: '20px' }}>
            <button
                style={{
                    backgroundColor: '#059CF4',
                    color: 'white',
                    padding: '15px 32px',
                    textAlign: 'center',
                    textDecoration: 'none',
                    display: 'inline-block',
                    fontSize: '16px',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    transition: 'background-color 0.3s',
                }}
                onClick={downloadPdf}
            >
                Receitas Fits em PDF
            </button>
        </div>
    );
}