
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Signin from "../components/Sign/Signin";
import Signup from "../components/Sign/SignUp/Signup";
import ProtectedRoute from "../routes/ProtectedRoute";
import Home from "../components/Home/Home";
import Termos from "../components/Termos/Termos";
import Suporte from "../components/SuporteFaleConosco/Suporte";
import LocalDieta from "../components/LocalDieta/LocalDieta";
import SobreMim from "../components/InfoUsuario/SobreMim";
import RedefinePassword from "../components/RedefinePassword/RedefinePassword";
import Agradecimentos from '../components/Kiwify/Agradecimento'
import Calorias from '../components/CaloriasConsumidas/Calorias';
import PageTreino from '../components/Treino/PageTreino';
import LocalTreino from '../components/Treino/LocalTreino';



function App() {

  return (
    <Routes>
      <Route path="/" element={<Signin />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/redefinirsenha" element={<RedefinePassword />} />
      <Route path="/termos" element={<Termos />} />
      <Route path="/calorias" element={<Calorias />} />
      <Route path="/suporte" element={<Suporte />} />
      <Route path="/dieta" element={<ProtectedRoute><LocalDieta /></ProtectedRoute>} />
      <Route path="/sobre" element={<ProtectedRoute><SobreMim /></ProtectedRoute>} />
      <Route path="/obrigado" element={<ProtectedRoute><Agradecimentos></Agradecimentos></ProtectedRoute>} />
      <Route path="/montartreino" element={<ProtectedRoute><PageTreino/></ProtectedRoute>} />
      <Route path="/treino" element={<ProtectedRoute><LocalTreino/></ProtectedRoute>} />
 

      <Route
        path="/home"
        element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        }
      />

    
    </Routes>
  );
}

export default App;
